import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const BusinessProfile = () => {
  const { businessId } = useParams();  // Get businessId from URL parameters
  const [businessData, setBusinessData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const businessDoc = await getDoc(doc(db, 'businesses', businessId));
        if (businessDoc.exists()) {
          setBusinessData(businessDoc.data());
        } else {
          setError('Business not found');
        }
      } catch (err) {
        setError('Failed to fetch business details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, [businessId]);

  if (loading) return <p>Loading business profile...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      {businessData && (
        <>
          <h1 className="text-3xl font-bold mb-4">{businessData.businessName}</h1>
          <div className="flex justify-center items-center">
             <img src={businessData.logoUrl} alt={businessData.businessName} className="w-32 h-32 object-cover rounded-full mb-4" />
            </div>          
            <p>
            <strong>Website:</strong> 
            {businessData.businessWebsite ? (
                <a href={businessData.businessWebsite.startsWith('https') ? businessData.businessWebsite : `https://${businessData.businessWebsite}`} 
                target="_blank" rel="noopener noreferrer">
                {businessData.businessWebsite}
                </a>
            ) : (
                'No website listed'
            )}
            </p>        
            </>
      )}
    </div>
  );
};

export default BusinessProfile;