import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import community from '../assets/city_people.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUser, faGift, faSignOutAlt, faRocket } from '@fortawesome/free-solid-svg-icons';
import OnboardingModal from '../components/OnboardingModal';
import GoogleSignIn from '../components/GoogleSignInButton';
import { getUserProfile } from '../services/userService';
import CoinImage from '../assets/coin_blank.png';

const Dashboard = () => {
  const [userRole, setUserRole] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const userEmail = user ? user.email : 'Anonymous';

  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit');
    if (!firstVisit) {
      setShowOnboarding(true);
      localStorage.setItem('firstVisit', 'true');
    }

    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setBalance(userDoc.data().neighborCoinBalance);
            const profile = await getUserProfile(user.uid);
            if (profile) {
              setUserRole(profile.role || 'attendee');
            } else {
              setError("User profile not found");
            }
          } else {
            setError("No user data found");
          }
        } catch (err) {
          setError(err.message);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [user]);

  const handleLogout = async () => {
    const authInstance = getAuth();
    document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    await signOut(authInstance);
  };

  const handleUpgradeClick = () => {
    navigate('/pricing');
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!auth.currentUser) {
    return (
      <div className="max-w-4xl mx-auto bg-white p-4 rounded-lg shadow-lg text-center">
        <div className="">
          <img src={community} alt="Neighbors gathering" className="" />
        </div>
        <p className="mb-4">You are not logged in.</p>
        <div>
          <GoogleSignIn />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-neutralLight p-2 rounded-lg shadow-lg">
      <h4 className="text-xl font-bold text-teal mb-4">Welcome, {userEmail}</h4>
      {showOnboarding && <OnboardingModal closeModal={() => setShowOnboarding(false)} />}
      {error && <p>{error}</p>}
      {balance !== null ? (
        <>
          <div className="flex flex-col bg-grayLight mb-6 items-center space-y-4">
            <h5 className="text-1xl font-bold mb-4 mt-6">Your NeighborhoodCoins Balance:</h5>

            <div className="text-white py-1 px-3 mb-3 rounded-full flex items-center justify-center w-20 h-20" style={{
              backgroundImage: `url(${CoinImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
              <p className="text-center">
                {balance}
                <span className="block text-sm text-shadow">Coins</span>
              </p>
            </div>
            <div className="flex justify-center">
              <Link to="/rewards">
                <button className="border-solid border-primary text-primary py-2 px-4 mb-6 rounded-lg hover:bg-highlight" style={{ borderWidth: '2px' }}>
                  Redeem Rewards
                </button>
              </Link>
            </div>
            <p className="text-center">Earn more NeighborhoodCoins at curated community events that build meaningful connections.</p>
          </div>

          {/* Role-Based Actions */}
          <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-5">
            {userRole === 'businessOwner' && (
              <>
                <Link to="/profile">
                  <button className="bg-secondary text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                   Update Profile Preferences
                  </button>
                </Link>
                <Link to="/business-dashboard">
                  <button className="bg-highlight text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    Business Dashboard
                  </button>
                </Link>
              </>
            )}

            {userRole === 'eventPlanner' && (
              <>
                <Link to="/event-admin">
                  <button className="bg-highlight text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    Events Planner Dashboard
                  </button>
                </Link>
                <Link to="/profile">
                  <button className="bg-secondary text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                    View Profile
                  </button>
                </Link>
              </>
            )}

            {userRole === 'attendee' && (
              <>
                <Link to="/profile">
                  <button className="bg-secondary text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                    Profile
                  </button>
                </Link>
                <Link to="/miami/events">
                  <button className="bg-highlight text-white py-2 px-4 rounded hover:bg-accent w-full md:w-auto">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    Events
                  </button>
                </Link>
                <Link to="/rewards">
                  <button className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight w-full md:w-auto">
                    <FontAwesomeIcon icon={faGift} className="mr-2" />
                    Rewards
                  </button>
                </Link>
              </>
            )}
          </div>

         {/* Pricing Section */}
            <div className="mt-8 mb-12 text-center bg-tealLight shadow-lg rounded-lg p-8">  {/* Add margin-bottom for spacing */}
              <h2 className="text-xl font-bold">Upgrade Your Experience</h2>
              <p>Unlock premium features and get the most out of NeighborhoodCoins.</p>
              <button
                className="bg-primary text-white py-2 px-4 mt-4 rounded-lg hover:bg-highlight"
                onClick={handleUpgradeClick}
              >
                <FontAwesomeIcon icon={faRocket} className="mr-2" />
                View Pricing Plans
              </button>
            </div>

            {/* Logout Button with additional margin */}
            <div className="flex justify-center">
            <button 
              className="bg-deepRed text-white py-4 px-6 mt-4 rounded hover:bg-highlight w-full md:w-auto"  // Added margin-top for spacing
              onClick={handleLogout}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </button>
          </div>
        </>
      ) : (
        <p>Loading your data...</p>
      )}
    </div>
  );
};

export default Dashboard;