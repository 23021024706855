import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth'; // Make sure to import your auth


// This is the custom hook that tracks authentication state
function useAuthState(auth) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true); // User is authenticated
            } else {
                setIsAuthenticated(false); // User is not authenticated
            }
        });

        // Clean up the subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    return isAuthenticated;
}

export default useAuthState;