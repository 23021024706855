import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase.js'; 
import NotFoundPage from './pages/NotFoundPage.js';  // Your 404 component
import Layout from './components/Layout.js';
import SignUp from './pages/SignUp.js';
import SignupProCheckout from './pages/SignUpPro.js';
import Home from './pages/Home.jsx';
import FinishSignUp from './pages/FinishSignUp.js';
import Dashboard from './pages/Dashboard.js';
import Events from './pages/Events.js';
import Rewards from './pages/Rewards.js';
import Profile from './pages/Profile.js';
import EventDetails from './pages/EventDetails.jsx';
import EventCalendar from './components/EventCalendar.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import BusinessProfile from './pages/BusinessProfile.jsx';
import EventForm from './components/EventForm.js';
import EditEventForm from './components/EditEventForm.js';
import HowItWorks from './components/HowItWorks.js';
import SignUps from './components/SignUps.js';
import SignupPage from './pages/SignupPage.jsx';
import BusinessSignUp from './components/BusinessSignUp.js';
import BusinessDashboard from './pages/BusinessDashboard.jsx';
import EditBusinessProfile from './components/EditBusinessProfile.js';
import Login from './pages/Login.js';
import CreateOffer from './components/CreateOffer.js';
import OurStory from './components/OurStory.js';
import EditOffer from './components/EditOffer.js';
import  EventAdmin from './pages/EventAdmin.js';
import 'leaflet/dist/leaflet.css';
import Neighborhoods from './pages/Neighborhoods.js';
import NeighborhoodPage from './pages/NeighborhoodPage.js';
import PricingPage from './pages/PricingPage.js'; // Import the PricingPage
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import { AuthProvider } from './context/AuthContext';
import ContactPage from './components/Contact.jsx';
import BusinessList from './components/BusinessList.jsx';
import BusinessPromotion from './components/BusinessPromo.jsx';
import News from './pages/News.jsx';
import SponsorForm from './components/SponsorForm';
import SponsorhipDashboard from './pages/SponsorshipDashboard.jsx';
import ArtBaselEvents from './pages/ArtBaselEvents.jsx';
import { Helmet } from 'react-helmet-async';


function App() {
  const[user, loading, error] = useAuthState(auth);
  
 
  if (loading) {
    // You can replace this with a spinner or any loading indicator
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <AuthProvider>
    <Router>
      <div className="min-h-screen bg-neutralLight text-neutral flex flex-col items-center justify-center">
     <Layout>
      <main className="p-2">
        <Routes>
         <Route path="/" element={<Home />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/login" element={<Login />} />
          <Route path="/finishSignUp" element={<FinishSignUp />} />
          <Route path="/signup-pro" element={<SignupProCheckout />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/miami/events" element={<Events />} />
          <Route path="/edit-offer/:offerId" element={<EditOffer />} />
          <Route path="/:slug-:location-:id" element={<EventDetails />} />
          <Route path="/news" element={<News />} />
          <Route path="/miami/event/:eventId" element={<EventDetails />} /> 
          <Route path="/edit-event/:eventId" element={<EditEventForm />} />
          <Route path="/event/edit/:eventId" element={<EditEventForm />} />
          {/* Dynamic neighborhood routes */}
        <Route path="/:cityName/:neighborhoodSlug" element={<NeighborhoodPage />} />
        <Route path="/business/:businessId" element={<BusinessProfile />} />
        <Route path="/art-basel-miami-events" element={<ArtBaselEvents />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/sponsor" element={<SponsorForm />} />
          <Route path="/sponsor-dashboard" element={<SponsorhipDashboard />} />
          <Route path="/event-admin-dashboard" element={ <ProtectedRoute><EventAdmin /></ProtectedRoute>} />
          <Route path="/signups" element={<SignUps />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/business-dashboard" element={<BusinessDashboard />} />
          <Route path="/business-profile-setup" element={<BusinessSignUp />} />
          <Route path="/edit-business-profile" element={<EditBusinessProfile />} /> 
          <Route path="/business-promotion" element={<BusinessPromotion />} />
          <Route path="/businesses" element={<BusinessList />} />
          <Route path="/create-offer" element={<CreateOffer />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route 
                path="/event-admin"
                element={
                  <ProtectedRoute>
                    <EventForm />
                  </ProtectedRoute>
                }
            />   
            <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />  {/* Catch-all route */}
   
        </Routes>
        </main>

       
        </Layout>
      </div>
    </Router>
    </AuthProvider>
     
   
  );
}

export default App;