import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase'; // Import your Firebase functions

const CheckoutForm = ({plan, amount}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Call Firebase function to create a payment intent
    const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
    const { data } = await createPaymentIntent({ amount}); 

    const clientSecret = data.clientSecret;

    // Confirm the payment using Stripe
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Customer Name',
        },
      },
    });

    if (result.error) {
      setError(result.error.message);
      setLoading(false);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setPaymentSuccess(true);
        setLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <CardElement className="p-2 border border-gray-300 rounded mb-4" />
      <button type="submit" 
      disabled={!stripe || loading} 
      className={`w-full py-2 px-4 rounded mt-5 bg-coral ${
        !stripe || loading
          ? 'bg-gray-400 cursor-not-allowed'
          : 'bg-coral-500 hover:bg-teal text-white'
      }`}
    >
      {loading ? 'Processing...' : 'Pay'}
    </button>
    {error && <p className="text-red-500">{error}</p>}
    {paymentSuccess && <p className="text-greenDark-700 mt-5">Payment successful!</p>}
  </form>
  );
};

export default CheckoutForm;