import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { neighborhoodData } from './Neighborhoods';
import NeighborhoodMap from '../components/NeighborhoodMap';

const NeighborhoodPage = () => {
  const { cityName, neighborhoodSlug } = useParams(); // Extract parameters from the URL
  const decodedCityName = decodeURIComponent(cityName);
  const decodedNeighborhoodSlug = decodeURIComponent(neighborhoodSlug);
  const [events, setEvents] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingBusinesses, setLoadingBusinesses] = useState(true);
  const [neighborhoodInformation, setNeighborhoodInformation] = useState(null);
  const [neighborhoodName, setNeighborhoodName] = useState('');
  const navigate = useNavigate();

  const normalizedNeighborhoodSlug = decodedNeighborhoodSlug.toLowerCase().replace(/\s+/g, '-');
  useEffect(() => {
    // Find the neighborhood name based on the slug
    if (neighborhoodData && typeof neighborhoodData === 'object') {
      const cityData = neighborhoodData[decodedCityName.toLowerCase()];
      if (cityData && typeof cityData === 'object') {
        const neighborhood = Object.values(cityData).find(n => n.slug === normalizedNeighborhoodSlug);
        
        if (neighborhood) {
          setNeighborhoodInformation(neighborhood);
          setNeighborhoodName(neighborhood.name);
        } else {
          console.error(`Neighborhood with slug "${normalizedNeighborhoodSlug}" not found in city "${decodedCityName}"`);
          navigate('/error'); // Redirect to an error page or handle the error appropriately
        }
      } else {
        console.error(`City "${decodedCityName}" not found in neighborhoodData`);
        navigate('/error'); // Redirect to an error page or handle the error appropriately
      }
    } else {
      console.error('Invalid neighborhoodData structure');
      navigate('/error'); // Redirect to an error page or handle the error appropriately
    }
  }, [decodedCityName, normalizedNeighborhoodSlug, navigate]);

  useEffect(() => {
    if (neighborhoodName) {
      const fetchEvents = async () => {
        try {
          const q = query(collection(db, 'events'), where('neighborhood', '==', neighborhoodName));
          const querySnapshot = await getDocs(q);
          const eventsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setEvents(eventsList);
        } catch (error) {
          console.error('Error fetching events: ', error);
        } finally {
          setLoadingEvents(false);
        }
      };

      fetchEvents();
    }
  }, [neighborhoodName]);

  useEffect(() => {
    if (neighborhoodName) {
      const fetchBusinesses = async () => {
        try {
          const q = query(collection(db, 'businesses'), where('neighborhood', '==', neighborhoodName));
          const querySnapshot = await getDocs(q);
          const businessesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setBusinesses(businessesList);
        } catch (error) {
          console.error('Error fetching businesses: ', error);
        } finally {
          setLoadingBusinesses(false);
        }
      };

      fetchBusinesses();
    }
  }, [neighborhoodName]);

  // Helper function to check if the event has ended
  const isEventEnded = (eventDate) => {
    const now = new Date();
    const eventDateObj = eventDate.toDate ? eventDate.toDate() : new Date(eventDate); // Convert Firestore Timestamp to Date if necessary
    return eventDateObj < now; // Check if event date is in the past
  };

  if (!neighborhoodInformation) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div>
        <div className="relative mb-5">
          <img
            src={neighborhoodInformation?.headerImage}
            alt={neighborhoodInformation?.name}
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white bg-black bg-opacity-30 px-2 py-2 rounded-lg">
              {neighborhoodInformation?.name} Neighborhood
            </h1>
          </div>
        </div>
        <div>
          <p className="text-1xl">{neighborhoodInformation?.description}</p>
        </div>
      </div>

      {/* Display Events */}
      <h1 className="text-3xl font-bold mt-8">Events</h1>
      {loadingEvents ? (
        <p>Loading events...</p>
      ) : (
        <>
          {events.length > 0 ? (
  events
    .sort((a, b) => {
      const dateA = new Date(a.date.seconds * 1000); 
      const dateB = new Date(b.date.seconds * 1000); // Convert Firestore Timestamp to Date object
      return dateB - dateA; // Sort in descending order
    })
    .map((event) => {
      const eventDate = new Date(event.date.seconds * 1000); // Convert Firestore Timestamp to Date object
      const eventEnded = isEventEnded(eventDate);

      return (
        <div key={event.id} className="flex items-center justify-between p-4 m-3 border-b border-teal rounded-lg shadow-lg bg-white">
          <div className="flex-1 mr-4">
            <Link to={`/events/${event.id}`}>
              <h3 className="text-lg font-semibold text-teal">{event.name}</h3>
            </Link>
            <p className="text-gray-500 text-bold mb-3">
              {eventDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })}
            </p>
            <p className="text-gray-600">{event.location}</p>

            {eventEnded && (
              <span className="text-red-600 font-bold">Event Ended</span>
            )}
          </div>
          <div className="w-24 h-24">
            <Link to={`/events/${event.id}`}>
              <img src={event.imageUrl || "path_to_default_image.jpg"} alt="Thumbnail" className="w-full h-full object-cover" />
            </Link>
          </div>
        </div>
      );
    })
) : (
  <p>No events available</p>
)}
        </>
      )}

      {/* Display Businesses */}
      <h1 className="text-3xl font-bold mt-8">Businesses</h1>
      {loadingBusinesses ? (
        <p>Loading businesses...</p>
      ) : (
        <>
          {businesses.length > 0 ? (
            <ul>
              {businesses.map((business) => (
                <li key={business.id} className="flex items-center justify-between mb-4 p-4 border border-teal rounded-lg shadow-md bg-white">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold">{business.businessName}</h3>
                    {business.businessWebsite && (
                      <p>
                        <strong>Website:</strong>{' '}
                        <a href={business.businessWebsite.startsWith('http') ? business.businessWebsite : `https://${business.businessWebsite}`} target="_blank" rel="noopener noreferrer">
                          {business.businessWebsite}
                        </a>
                      </p>
                    )}
                    {business.location && (
                      <p>
                        <strong>Location:</strong> {business.location}
                      </p>
                    )}
                  </div>
                  {business.logoUrl && (
                    <div className="w-24 h-24 ml-4">
                      <img src={business.logoUrl} alt={business.businessName} className="w-full h-full object-cover rounded-lg" />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No businesses found.</p>
          )}
        </>
      )}

      {/* Call to Action */}
      <div className="mt-8 text-center bg-lightPurple p-4">
        <h2 className="text-2xl font-bold mb-4">Add Your Business or Event</h2>
        <p className="text-lg mb-4">Do you have a business or event that you want to share with the community? Sign up now to get started!</p>
        <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
          Sign Up
        </Link>
      </div>
      
    </div>
    
    
  );
};

export default NeighborhoodPage;