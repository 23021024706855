import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import community from '../assets/city_people.png';
import Login from './Login'; // Import the Login component

const Rewards = () => {
  const [rewards, setRewards] = useState([]);
  const [businesses, setBusinesses] = useState({}); // Store business data
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [showLogin, setShowLogin] = useState(null); // State to show login for a specific offer

  useEffect(() => {
    const fetchRewardsAndBusinesses = async () => {
      try {
        // Fetch all rewards from Firestore
        const rewardsCollection = collection(db, 'offers');
        const rewardsSnapshot = await getDocs(rewardsCollection);
        const rewardsList = rewardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Collect business IDs from the rewards
        const businessIds = [...new Set(rewardsList.map(reward => reward.businessId))];
        
        // Fetch the corresponding business details for each businessId
        const businessesData = {};
        for (const businessId of businessIds) {
          const businessDoc = await getDoc(doc(db, 'businesses', businessId));
          if (businessDoc.exists()) {
            businessesData[businessId] = businessDoc.data();
          }
        }

        setRewards(rewardsList);
        setBusinesses(businessesData); // Store the business details in the state
      } catch (err) {
        setError('Failed to fetch rewards or business data: ' + err.message);
      }
    };

    fetchRewardsAndBusinesses();
  }, []);

  const handlePurchaseReward = async (reward) => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Check if the user has enough NeighborCoins
        const userRef = doc(db, 'users', user.email);
        const userDoc = await getDoc(userRef);
        const userBalance = userDoc.data().neighborCoinBalance;

        if (userBalance >= reward.coinOffer) {
          // Deduct the cost from the user's NeighborCoin balance
          await updateDoc(userRef, {
            neighborCoinBalance: increment(-reward.coinOffer)
          });
          setMessage(`You have successfully purchased ${reward.offerTitle}!`);
        } else {
          setError('Insufficient NeighborCoins');
        }
      } catch (err) {
        setError('Failed to purchase reward: ' + err.message);
      }
    } else {
      setShowLogin(reward.id); // Show the login for this specific reward
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Local Reward Partners</h2>
          <p className="text-lg text-gray-600">Redeem your NeighborCoins for exclusive local offers</p>
        </div>

        {message && (
          <div className="mb-8 p-4 bg-green-100 border border-green-400 text-green-700 rounded-lg">
            {message}
          </div>
        )}
        
        {error && (
          <div className="mb-8 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {rewards.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 text-lg">No rewards available at this time.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {rewards.map(reward => {
              const business = businesses[reward.businessId];
              return (
                <div key={reward.id} className="bg-white rounded-xl shadow-md overflow-hidden transition-transform hover:scale-[1.02]">
                  <div className="relative h-48">
                    <img 
                      src={reward.offerImageUrl || 'https://via.placeholder.com/150'} 
                      alt={reward.offerTitle} 
                      className="w-full h-full object-cover" 
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex items-center mb-4">
                      {business?.logoUrl && (
                        <img 
                          src={business.logoUrl} 
                          alt={`${business.businessName} logo`} 
                          className="w-10 h-10 rounded-full mr-3"
                        />
                      )}
                      <div>
                        <h3 className="text-xl font-semibold text-gray-900">{reward.offerTitle}</h3>
                        {business && (
                          <p className="text-sm text-gray-600">{business.businessName}</p>
                        )}
                      </div>
                    </div>
                    <p className="text-gray-600 mb-4">{reward.offerDescription}</p>
                    <div className="flex items-center justify-between">
                      <span className="text-primary font-bold">{reward.coinOffer} NeighborCoins</span>
                      {showLogin === reward.id ? (
                        <div className="w-full bg-white rounded-lg">
                          <Login />
                        </div>
                      ) : (
                        <button
                          onClick={() => handlePurchaseReward(reward)}
                          className="bg-primary hover:bg-primary/90 text-white font-semibold py-2 px-6 rounded-lg transition-colors duration-300"
                        >
                          Redeem
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Rewards;