import React from 'react';
import { Link }from 'react-router-dom';
import NeighborhoodBanner from '../assets/neighborhood_coin_banner.png';

const OurStory = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold text-center mb-6 text-teal">Our Story</h1>
      <div className="text-lg leading-relaxed text-gray-700">
        <img src={NeighborhoodBanner} alt="Neighborhood Coins" className="mx-auto" />
        <h2 className="mb-4 text-2xl font-bold">
          Ever wished there was more to an event experience?
          </h2>
          <p className="mb-4">
           What if attending local events could also mean supporting your community and discovering new businesses—while earning rewards?
        </p>
        <p className="mb-4">
          That’s where NeighborhoodCoins comes in. 
        </p>
        <p className="mb-4">
          Born from the idea of connecting event planners, local businesses, and attendees, NeighborhoodCoins rewards you for showing up, participating, and supporting your city. Every event you attend earns you digital coins, which can be redeemed at local businesses. 
        </p>
        <p className="mb-4">
          It’s more than events—it’s about building stronger communities, one reward at a time.
        </p>
       <div className="mx-auto">
       <p className="mb-6 flex justify-center items-center">
            <Link to="/signup">
                <button className="bg-teal text-white py-2 px-4 font-bold rounded-lg hover:bg-burntOrange">
                Join us in Miami
                </button>
            </Link>
            </p>
        </div>
         <p>Let’s make our cities better, together.
        </p>
      </div>
    </div>
  );
};

export default OurStory;