import React, { useEffect, useState } from 'react';
import { storage, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useParams, useNavigate } from 'react-router-dom';

const EditEventForm = () => {
  const { eventId } = useParams();  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    date: '',
    endDate: '', // Add end date to initial state
    location: '',
    neighborhood: '',
    coins: 0,
    startTime: '',
    endTime: '',
    doorsOpenTime: '',
    organizer: '',
    reward: '',
    ticketPrice: '',
    ticketQuantity: '',
    ticketUrl: '', // Add ticket URL field
  });

  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [message, setMessage] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      if (!eventId) {
        navigate('/event-admin');
        return;
      }

      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);
        
        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          // Convert Firestore Timestamp to date string for input field
          const date = eventData.date.toDate().toISOString().split('T')[0];
          
          setFormData({
            ...eventData,
            date: date,
          });
          
          setImageUrl(eventData.imageUrl);
        } else {
          setMessage('Event not found');
          navigate('/event-admin');
        }
      } catch (err) {
        console.error('Error fetching event data: ', err);
        setMessage('Error loading event');
      }
    };

    fetchEvent();
  }, [eventId, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const eventRef = doc(db, 'events', eventId);
      
      let updateData = { ...formData };

      if (imageFile) {
        const imageRef = ref(storage, `events/${eventId}/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(imageRef, imageFile);
        
        await uploadTask;
        const downloadURL = await getDownloadURL(imageRef);
        updateData.imageUrl = downloadURL;
      }

      await updateDoc(eventRef, updateData);
      setMessage('Event updated successfully!');
      
      // Redirect back to event admin after short delay
      setTimeout(() => {
        navigate('/event-admin');
      }, 1500);
      
    } catch (error) {
      console.error('Error updating event: ', error);
      setMessage('Error updating event. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Edit Event</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        {/* Event Name */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event name (Max 50 characters)</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            maxLength="50"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        
        {/* Event Description */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Description (Max 600 characters)</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            rows="4"
            maxLength="600"
            required
          />
        </div>

        {/* Date and Time Section */}
        <div className="bg-gray-50 p-6 rounded-lg mb-8">
          <h3 className="text-lg font-semibold text-teal mb-4">Date and Time</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="mb-4">
              <label className="block text-neutral mb-2">Start Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-neutral mb-2">End Date</label>
              <input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            {/* Event Start Time */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">Event Start Time</label>
              <input
                type="time"
                name="startTime"
                value={formData.startTime}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Event End Time */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">Event End Time</label>
              <input
                type="time"
                name="endTime"
                value={formData.endTime}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Doors Open Time */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">Doors Open Time</label>
              <input
                type="time"
                name="doorsOpenTime"
                value={formData.doorsOpenTime}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Location */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Neighborhood */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Neighborhood</label>
          <input
            type="text"
            name="neighborhood"
            value={formData.neighborhood}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Number of NeighborhoodCoins */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">NeighborhoodCoins</label>
          <input
            type="number"
            name="coins"
            value={formData.coins}
            onChange={handleChange}
            max="5"
            min="0"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Ticket Price (Optional) */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Price ($) (Optional)</label>
          <input
            type="number"
            name="ticketPrice"
            value={formData.ticketPrice}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Ticket Quantity (Optional) */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Quantity (Optional)</label>
          <input
            type="number"
            name="ticketQuantity"
            value={formData.ticketQuantity}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Ticket Purchase URL */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Ticket Purchase URL</label>
          <input
            type="url"
            name="ticketUrl"
            value={formData.ticketUrl}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            placeholder="https://ticketing-website.com/your-event"
          />
        </div>

        {/* Image Upload */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Event Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        {uploading ? (
          <p>Uploading image...</p>
        ) : (
          <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
            Update Event
          </button>
        )}
      </form>
    </div>
  );
};

export default EditEventForm;