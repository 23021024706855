import React, { useEffect, useState } from 'react';
import { functions, auth, storage, db } from '../firebase';  
import {doc, getDoc, addDoc, collection } from 'firebase/firestore'; 
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';  
import { Link } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import axios from 'axios';
import Profile from  '../pages/Profile';
import EventAdmin from '../pages/EventAdmin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faStoreAlt, faCheckCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CoinImage from '../assets/coin_blank.png';
import { createSlug } from '../utils/createSlug';


const EventForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    date: '',
    endDate: '', // Add end date field
    location: '',//This is the address of the event
    venue: '',
    neighborhood: '',
    coins: 0,
    startTime: '',
    endTime: '',
    doorsOpenTime: '',
    organizer: '',
    reward: '',
    ticketPrice: '', // Allows empty ticket pricing
    ticketQuantity: '', // Allows empty ticket quantity
    isSponsorable: false, // Default to false for sponsorships
    isArtBasel: false, 
    ticketUrl: '', // Add ticket URL field
  });
  const [latLong, setLatLong] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [message, setMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [uploading, setUploading] = useState(false); 
  const [modalOpen, setModalOpen] = useState(false);
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isRewardsOpen, setIsRewardsOpen] = useState(false);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const geocodeAddress = async (address) => {
    try {
      setMapLoading(true);
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address,
          key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY, // Your API key
        }
      });
      console.log(response.data);  // Log the response to check
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setLatLong({ lat: location.lat, lng: location.lng }); // Save the latitude and longitude
        return { lat: location.lat, lng: location.lng }; // Return latitude and longitude
      } else {
        console.error("No results found for the provided address");
        return null; // Return null if geocoding fails
      }
    } catch (error) {
      console.error("Geocoding API error: ", error);
      return null; // Return null in case of error
    } finally {
      setMapLoading(false);
    }
  };

// Call geocodeAddress when user finishes typing in the location field
const handleAddressBlur = async (e) => {
  const { value } = e.target;
  if (value) {
    const location = await geocodeAddress(value);
    if (location) {
      // Successfully got lat/lng, you can store it in state or pass it to a function
      setLatLong(location);  // This stores lat/lng for later use
      console.log('Lat/Long:', location.lat);  // Check if it logs the correct values
    } else {
      console.error('Failed to fetch lat/long for the provided address');
    }
  }
};

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);  // Store the selected image file
  };

  // Validation
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name?.trim()) {
      newErrors.name = 'Event name is required';
    }
    
    if (!formData.date) {
      newErrors.date = 'Event date is required';
    }

    if (!formData.location?.trim()) {
      newErrors.location = 'Event location is required';
    }

    if (!formData.startTime) {
      newErrors.startTime = 'Start time is required';
    }

    if (!formData.venue?.trim()) {
      newErrors.venue = 'Venue name is required';
    }

    // Optional fields validation (only if values are provided)
    if (formData.ticketPrice && formData.ticketPrice < 0) {
      newErrors.ticketPrice = 'Ticket price cannot be negative';
    }

    if (formData.ticketQuantity && formData.ticketQuantity < 1) {
      newErrors.ticketQuantity = 'Ticket quantity must be at least 1';
    }

    if (formData.coins && (formData.coins < 1 || formData.coins > 5)) {
      newErrors.coins = 'Coins must be between 1 and 5';
    }

    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
     // Prepare the form data for submission
  const eventData = {
    ...formData,
    isArtBasel: formData.isArtBasel, // Include the Art Basel event status
  };
  
    //Prevent multiple form submissions
    setUploading(true);

    // Fetch lat/long based on the address
    const location = await geocodeAddress(formData.location);
    if (!location) {
      setMessage("Invalid address.");
      return;
    }
  
    // Use the user's local date without forcing UTC
    const userDate = new Date(formData.date + "T00:00:00"); // Date without any timezone conversion
    const eventDate = Timestamp.fromDate(userDate); // Convert to Firestore Timestamp
  

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        setMessage('You need to be logged in to create an event.');
        return;
      }
      
       // Generate the SEO-friendly URL
    const eventSlug = createSlug(formData.name);
    const locationSlug = createSlug(formData.location);
    const eventUrl = `/miami/event/${eventSlug}-${locationSlug}-${Date.now()}`;
    console.log("Generated Event URL:", eventUrl);  // Debug line
      // Upload image to Firebase Storage
      let imageUrl = '';
      if (imageFile) {
        const imageRef = ref(storage, `events/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(imageRef, imageFile);
  
        // Wait for the upload to complete and get the download URL
        await new Promise((resolve, reject) => {
          uploadTask.on('state_changed', 
            null, 
            (error) => reject(error), 
            async () => {
              imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            });
        });
      }
  
      // Prepare form data with normalized date and organizerId(uid)
      const eventData = {
        ...formData,
        date: eventDate, // Save the Firestore Timestamp
        imageUrl: imageUrl,  // Save the image URL
        latitude: location.lat,  // Store latitude
        longitude: location.lng,  // Store longitude
        organizerId: user.uid, // Save the organizerId as user's ID
        isSponsorable: formData.isSponsorable || false, // Default to false if not specified
        sponsoredBy: null,  // Initialize as empty (to be updated later when a business sponsors the event)
        eventUrl // Save the SEO event URL

      };
  
      const eventsRef = collection(db, 'events');
      await addDoc(eventsRef, eventData);  // Save to Firestore
      setMessage('Awesome! Your Event successfully added to NeighborhoodCoins Events! See the Events page. EDIT it on the Events Details page.');
      setModalOpen(true);
    } catch (error) {
      console.error('Error adding event: ', error);
      setMessage('Error adding event. Please try again.');
    } finally {
      setUploading(false);
    }
  };

   const closeModal = () => {
    setModalOpen(false);
  };
  // Fetch user data when component mounts
  useEffect(() => {
    const auth = getAuth();
    
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch user profile from Firestore
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          setUserData(userDoc.data());  // Set the user data
        } else {
          setMessage('User data not found');
        }
      }
    });
  }, []);
  

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-3xl font-bold text-teal">
              Welcome, {userData ? userData.displayName : 'User'}
            </h1>
            <p className="text-gray-600 mt-2">Manage your events and rewards</p>
          </div>
          <div className="flex items-center space-x-4">
            <div className="text-center">
              <p className="text-sm text-gray-600 mb-1">Your Balance</p>
              <div className="coin-display flex items-center bg-teal bg-opacity-10 rounded-full px-4 py-2">
                <img src={CoinImage} alt="coins" className="w-6 h-6 mr-2" />
                <span className="font-bold text-teal">{userData ? userData.neighborCoinBalance : '...'}</span>
              </div>
            </div>
            <Link 
              to="/rewards" 
              className="bg-mustard text-white py-2 px-4 rounded-lg hover:bg-teal transition-colors"
            >
              Redeem Coins
            </Link>
          </div>
        </div>
      </div>

      {/* Create Event Section */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-8">
        <div 
          className="bg-teal p-6 flex justify-between items-center cursor-pointer"
          onClick={() => setIsEventsOpen(!isEventsOpen)}
        >
          <h2 className="text-2xl font-semibold text-white">Create a New Event</h2>
          <FontAwesomeIcon 
            icon={isEventsOpen ? faChevronUp : faChevronDown} 
            className="text-white"
          />
        </div>

        {isEventsOpen && (
          <div className="p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Basic Information Section */}
              <div className="bg-gray-50 p-6 rounded-lg mb-8">
                <h3 className="text-lg font-semibold text-teal mb-4">Basic Information</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Event Name */}
                  <div>
                    <label className="block text-gray-700 mb-2">
                      Event Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      maxLength="50"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal focus:border-transparent"
                      required
                    />
                  </div>

                  {/* Venue */}
                  <div>
                    <label className="block text-gray-700 mb-2">
                      Venue <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="venue"
                      value={formData.venue}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal focus:border-transparent"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Date and Time Section */}
              <div className="bg-gray-50 p-6 rounded-lg mb-8">
                <h3 className="text-lg font-semibold text-teal mb-4">Date and Time</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="mb-4">
                    <label className="block text-neutral mb-2">Start Date</label>
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-neutral mb-2">End Date</label>
                    <input
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-neutral mb-2">Event Start Time</label>
                    <input
                      type="time"
                      name="startTime"
                      value={formData.startTime}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-neutral mb-2">Event End Time</label>
                    <input
                      type="time"
                      name="endTime"
                      value={formData.endTime}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-neutral mb-2">Doors Open Time</label>
                    <input
                      type="time"
                      name="doorsOpenTime"
                      value={formData.doorsOpenTime}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Location and Details Section */}
              <div className="bg-gray-50 p-6 rounded-lg mb-8">
                <h3 className="text-lg font-semibold text-teal mb-4">Location and Details</h3>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Event address</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    onBlur={handleAddressBlur}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Event Description (Max 500 characters)</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    rows="4"
                    placeholder="Enter event description"
                    maxLength="500"
                  ></textarea>
                  {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
                </div>
              </div>

              {/* Optional Settings Section */}
              <div className="bg-gray-50 p-6 rounded-lg mb-8">
                <h3 className="text-lg font-semibold text-teal mb-4">Additional Settings</h3>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Neighborhood (Optional)</label>
                  <input
                    type="text"
                    name="neighborhood"
                    value={formData.neighborhood}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Optional"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Ticket Price ($) (Optional)</label>
                  <input
                    type="number"
                    name="ticketPrice"
                    value={formData.ticketPrice}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Optional"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Ticket Quantity (Optional)</label>
                  <input
                    type="number"
                    name="ticketQuantity"
                    value={formData.ticketQuantity}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Optional"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Organizer Company Name</label>
                  <input
                    type="text"
                    name="organizer"
                    value={formData.organizer}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">NeighborhoodCoins (Max 5)</label>
                  <input
                    type="number"
                    name="coins"
                    value={formData.coins}
                    onChange={handleChange}
                    max="5"
                    min="0"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Reward Offering</label>
                  <input
                    type="string"
                    name="reward"
                    placeholder="Enter perk that attendees can earn more coins"
                    value={formData.reward}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Ticket Purchase URL</label>
                  <input
                    type="url"
                    name="ticketUrl"
                    value={formData.ticketUrl}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="https://ticketing-website.com/your-event"
                  />
                  <p className="text-sm text-gray-600 mt-1">
                    Add the link where attendees can purchase tickets or RSVP
                  </p>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    <input
                      type="checkbox"
                      name="isArtBasel"
                      checked={formData.isArtBasel}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    Is this an Art Basel event?
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Allow Event Sponsorship</label>
                  <input
                    type="checkbox"
                    name="isSponsorable"
                    checked={formData.isSponsorable}
                    onChange={(e) => setFormData({ ...formData, isSponsorable: e.target.checked })}
                    className="mr-2"
                  />
                  <span>Yes, this event can be sponsored</span>
                </div>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Event Image</label>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end">
                <button 
                  type="submit" 
                  className="bg-teal text-white py-3 px-8 rounded-lg hover:bg-mustard transition-colors"
                  disabled={uploading}
                >
                  {uploading ? 'Creating Event...' : 'Create Event'}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* Success Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
            <h3 className="text-2xl font-bold text-teal mb-4">Success!</h3>
            <p className="text-gray-600 mb-6">{message}</p>
            <button
              onClick={closeModal}
              className="w-full bg-teal text-white py-2 rounded-lg hover:bg-mustard transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Your Events Section */}
      <div className="mb-8 p-4 bg-mustard rounded-lg">
        <h3 className="text-2xl text-white  font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsProfileOpen(!isProfileOpen)}>
         View Your Events
          <FontAwesomeIcon icon={isProfileOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isProfileOpen && (
          <>
            <EventAdmin />
          </>
        )}
        </div>
    
      {/* Profiles Section */}
      <div className="mb-8 bg-lightPurple p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsProfileOpen(!isProfileOpen)}>
          Update Your Profile
          <FontAwesomeIcon icon={isProfileOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isProfileOpen && (
          <>
            <Profile />
          </>
        )}
      </div>
    
      {/* Rewards Section */}
      <div className="mb-8 bg-green p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsRewardsOpen(!isRewardsOpen)}>
          Available Rewards
          <FontAwesomeIcon icon={isRewardsOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isRewardsOpen && (
          <>
            <p className="text-neutral mb-4">
              Redeem your NeighborhoodCoins for discounts and rewards at local businesses. Check out the rewards page to see what's available.
            </p>
            <Link to="/rewards" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight flex items-center justify-center">
              <FontAwesomeIcon icon={faStoreAlt} className="mr-2" />
              View Rewards
            </Link>
          </>
        )}
      </div>
      
  
    {message && <p className="font-bold text-greenDark">{message}</p>}
    
  </div>
  
  );
};

export default EventForm;