import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import CoinImage from '../assets/coin_blank.png';
import GoogleSignInButton from '../components/GoogleSignInButton';
import ArtBaselBanner from '../assets/artbasel_paint.png';  // Add this import
import { formatDate, formatTime } from '../utils/dateUtils';  // Add this import

const ArtBaselEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [rsvpMessages, setRsvpMessages] = useState({});
  const [signupReason, setSignupReason] = useState('');

  // Add handleSponsorClick function
  const handleSponsorClick = (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      setSignupReason('sponsor');
      setShowSignup(true);
    } else {
      window.location.href = '/sponsor-dashboard';
    }
  };

  const handleEarnCoins = (event) => {
    if (!auth.currentUser) {
      setSignupReason('coins');
      setShowSignup(true);
      return;
    }
    // Implement RSVP logic here similar to EventDetails
    handleRsvp(event);
  };

  useEffect(() => {
    const fetchArtBaselEvents = async () => {
      try {
        const q = query(collection(db, 'events'), where('isArtBasel', '==', true));
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Sort events by date
        const sortedEvents = eventsList.sort((a, b) => {
          const dateA = a.date.toDate ? a.date.toDate() : new Date(a.date);
          const dateB = b.date.toDate ? b.date.toDate() : new Date(b.date);
          return dateA - dateB;
        });
        
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Error fetching Art Basel events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArtBaselEvents();
  }, []);

  const isEventEnded = (eventDate) => {
    const now = new Date();
    const eventDateObj = eventDate.toDate ? eventDate.toDate() : new Date(eventDate); // Convert Firestore Timestamp to Date if necessary
    return eventDateObj < now; // Check if event date is in the past
  };

  const getEventLabel = (ticketPrice) => {
    return ticketPrice && ticketPrice > 0 ? "PAID" : "FREE";
  };

  const handleRsvp = async (event) => {
    const user = auth.currentUser;
    if (!user) {
      setShowSignup(true);
      return;
    }

    try {
      const eventRef = doc(db, 'events', event.id);
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpMessages(prev => ({
        ...prev,
        [event.id]: `Congratulations! You have RSVP'd to ${event.name}. You have ${event.coins} NeighborhoodCoins pending for participating.`
      }));
    } catch (err) {
      console.error('Failed to RSVP:', err);
      setRsvpMessages(prev => ({
        ...prev,
        [event.id]: 'Failed to RSVP. Please try again.'
      }));
    }
  };

  const closeModal = () => {
    setShowSignup(false);
  };

  return (
    <div className="min-h-screen bg-neutralLight font-sans">
      {/* Art Basel Banner */}
      <div 
        className="relative bg-teal py-24 mb-12"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 128, 128, 0.7), rgba(0, 128, 128, 0.9)), url(${ArtBaselBanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="container mx-auto px-4 relative text-center">
          <h1 className="font-display text-4xl font-bold text-white mb-4">
            Art Basel Miami 2024
          </h1>
          <p className="text-white text-xl">December 6-8, 2024</p>
        </div>
      </div>

      <div className="container mx-auto px-4 pb-16">
        {/* Unified CTA Section */}
        <div className="mb-12 bg-white rounded-lg shadow-sm p-8">
          <h2 className="text-2xl font-bold text-teal text-center mb-6">
            Get Involved with Art Basel Miami
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-4">
            <Link 
              to="/signup?role=eventplanner"
              className="flex-1 bg-gradient-to-r from-teal to-mustard text-white text-center font-bold py-4 px-6 rounded-lg shadow-md transform transition-transform hover:scale-102 hover:shadow-lg max-w-md mx-auto"
            >
              <div className="flex flex-col items-center">
                <span className="text-xl mb-1">Submit Your Event</span>
                <span className="text-sm">For Event Planners</span>
              </div>
            </Link>
            <a 
              href="#"
              onClick={handleSponsorClick}
              className="flex-1 bg-white text-teal border-2 border-teal text-center font-bold py-4 px-6 rounded-lg shadow-md transform transition-transform hover:scale-102 hover:bg-teal hover:text-white max-w-md mx-auto"
            >
              <div className="flex flex-col items-center">
                <span className="text-xl mb-1">Promote Your Business</span>
                <span className="text-sm">Sponsorship Opportunities</span>
              </div>
            </a>
          </div>
        </div>

      

        {/* Replace the existing signup prompt with modal */}
        {showSignup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
              <h3 className="text-2xl font-bold text-teal mb-6">
                {signupReason === 'sponsor' 
                  ? 'Please Sign In to Access Sponsorship Dashboard' 
                  : 'Sign Up to Earn Coins'}
              </h3>
              <GoogleSignInButton />
              <button
                onClick={closeModal}
                className="mt-4 w-full bg-gray-200 text-gray-700 py-2 rounded-lg hover:bg-gray-300 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        )}

        {/* Events Grid */}
        {loading ? (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-teal"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
            {events.length > 0 ? (
              events.map((event) => (
                <div key={event.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-102">
                  <div className="relative">
                    <Link to={`/miami/event/${event.id}`}>
                      <img 
                        src={event.imageUrl || "/path-to-default-image.jpg"} 
                        alt={event.name}
                        className="w-full h-64 object-cover"
                      />
                      {/* Add neighborhood badge */}
                      <div className="absolute top-4 left-4 bg-teal text-white px-3 py-1 rounded-full font-bold text-sm">
                        {event.neighborhood || 'Miami'}
                      </div>
                      {/* Existing price label */}
                      <div className="absolute top-4 right-4">
                        <div className="bg-mustard text-teal px-3 py-1 rounded-full font-bold text-sm">
                          {getEventLabel(event.ticketPrice)}
                        </div>
                      </div>
                      </Link>
                    </div>

                  <div className="p-6">
                    {/* Event Details */}
                    <Link to={`/miami/event/${event.id}`} className="block mb-4">
                      <h4 className="text-2xl font-bold text-teal hover:text-mustard transition-colors">
                        {event.name}
                      </h4>
                    </Link>

                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center text-gray-600">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="text-teal mr-2" />
                        <span className="text-teal">{event.location}</span>
                      </div>
                      <div className="coin-display flex items-center bg-teal bg-opacity-10 rounded-full px-4 py-2">
                        <img src={CoinImage} alt="coins" className="w-6 h-6 mr-2" />
                        <span className="font-bold text-teal">+{event.coins}</span>
                      </div>
                    </div>

                    {/* Date and Time */}
                    <div className="text-gray-600 font-medium mb-4">
                      {new Date(event.date.seconds * 1000).toLocaleDateString('en-US', {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </div>

                    {/* Add clarification text */}
                    <div className="text-center text-sm text-gray-600 mb-4">
                      *Use NeighborCoin to earn rewards at local events. Event registration/tickets handled separately by organizers.
                    </div>

                    <div className="flex flex-col space-y-4">
                      {/* Add Ticket URL Button */}
                      {event.ticketUrl && (
                        <a
                          href={event.ticketUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-mustard text-white py-2 px-4 rounded-lg text-center hover:bg-teal transition-colors"
                        >
                          Purchase Tickets / RSVP
                        </a>
                      )}

                      {/* Updated coin display */}
                      <div 
                        onClick={() => handleRsvp(event)}
                        className="coin-display flex items-center justify-center bg-teal bg-opacity-10 rounded-full px-4 py-2 hover:bg-opacity-20 cursor-pointer"
                      >
                        <img src={CoinImage} alt="coins" className="w-6 h-6 mr-2" />
                        <span className="font-bold text-teal">Earn {event.coins} Coins</span>
                      </div>
                    </div>

                    {/* RSVP Messages */}
                    {rsvpMessages[event.id] && (
                      <div className="mt-4 p-3 bg-teal bg-opacity-10 rounded-lg">
                        <p className="text-teal font-medium">{rsvpMessages[event.id]}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center py-12">
                <p className="text-xl text-gray-600">No Art Basel events available yet. Check back soon!</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtBaselEvents;