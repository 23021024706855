import React from 'react';
import GoogleButton from '../components/GoogleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">
        Login or Sign Up
        </h2>


      <p className="mt-4 text-center">
        <GoogleButton />
      </p>
    </div>
  );
};

export default Login;