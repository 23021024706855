import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Firestore instance
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';

const SignUps = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';  // Get email passed from GoogleSignInButton

  const [role, setRole] = useState('attendee');  // Define a state variable for role

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
  
    try {
      // Update the user's role in Firestore
      const userRef = doc(db, 'users', user.uid);  // Use UID for user reference
      await updateDoc(userRef, { role });  // Update the document with the selected role

      // Redirect to the appropriate dashboard based on role
      switch (role) {
        case 'attendee':
          navigate('/dashboard');
          break;
        case 'eventPlanner':
          navigate('/event-admin');
          break;
        case 'businessOwner':
          navigate('/business-dashboard');
          break;
        default:
          navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-mustard rounded-lg shadow-lg">
      <h2 className="text-center font-bold mb-4">Get started with NeighborhoodCoins</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block font-bold mb-2">I am signing up as a:</label>
          <select value={role} onChange={(e) => setRole(e.target.value)} className="w-full p-2 border border-gray-300 rounded-lg">
            <option value="attendee">Attendee</option>
            <option value="eventPlanner">Event Planner</option>
            <option value="businessOwner">Business Owner</option>
          </select>
        </div>
        <button type="submit" className="w-full bg-teal text-white py-2 px-4 font-bold rounded-lg hover:bg-burntOrange">
          Continue
        </button>
      </form>
    </div>
  );
};

export default SignUps;