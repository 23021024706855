import React from 'react';  
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { AdvancedMarkerElement, PinElement } from '@googlemaps/markerwithlabel';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};
const coinIconUrl = 'https://firebasestorage.googleapis.com/v0/b/neighborcoin-8c7ee.appspot.com/o/images%2Ffavicon-32x32.png?alt=media&token=44d3b4e2-8874-4125-a04a-fb08201f2a39';
function Map({ event }) {
    const center = {
        lat: event.latitude, // Replace with actual latitude field from your event data
        lng: event.longitude, // Replace with actual longitude field from your event data
      };
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center} 
        zoom={17}
      >
          <Marker
            position={center} 
            title={event.name}
            icon ={{
              url: coinIconUrl,
              
            }}
            
          />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;