import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faEdit, faUsers, faClock } from '@fortawesome/free-solid-svg-icons';

const EventAdmin = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchEvents = async () => {
        try {
          const eventsCollection = collection(db, 'events');
          const q = query(eventsCollection, where('organizerId', '==', user.uid));
          const eventsSnapshot = await getDocs(q);
          const eventsData = eventsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          // Sort events by date in ascending order
          const sortedEvents = eventsData.sort((a, b) => {
            const dateA = a.date.seconds;
            const dateB = b.date.seconds;
            return dateB - dateA;
          });
          
          setEvents(sortedEvents);
        } catch (err) {
          console.error('Failed to fetch events:', err);
        }
      };

      fetchEvents();
    }
  }, [isAuthenticated, user]);

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
        <h1 className="text-3xl font-bold text-teal mb-2">Event Management</h1>
        <p className="text-gray-600">Manage and track your events</p>
      </div>

      {isAuthenticated && (
        <div className="space-y-6">
          {/* Event Stats Overview */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex items-center space-x-3 text-teal mb-2">
                <FontAwesomeIcon icon={faCalendar} />
                <h3 className="font-semibold">Total Events</h3>
              </div>
              <p className="text-2xl font-bold">{events.length}</p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex items-center space-x-3 text-teal mb-2">
                <FontAwesomeIcon icon={faUsers} />
                <h3 className="font-semibold">Total RSVPs</h3>
              </div>
              <p className="text-2xl font-bold">
                {events.reduce((total, event) => total + (event.rsvps?.length || 0), 0)}
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex items-center space-x-3 text-teal mb-2">
                <FontAwesomeIcon icon={faClock} />
                <h3 className="font-semibold">Upcoming Events</h3>
              </div>
              <p className="text-2xl font-bold">
                {events.filter(event => new Date(event.date.seconds * 1000) > new Date()).length}
              </p>
            </div>
          </div>

          {/* Events List */}
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="border-b border-gray-200 bg-gray-50 p-4">
              <h2 className="text-xl font-semibold text-teal">Your Events</h2>
            </div>
            
            <div className="divide-y divide-gray-200">
              {events.map(event => (
                <div key={event.id} className="p-6 hover:bg-gray-50 transition-colors">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-1">{event.name}</h3>
                      <p className="text-gray-600 text-sm mb-2">
                        {new Date(event.date.seconds * 1000).toLocaleDateString('en-US', {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </p>
                      <div className="flex items-center space-x-4 text-sm">
                        <span className="text-gray-500">
                          RSVPs: {event.rsvps ? event.rsvps.length : 0}
                        </span>
                        {event.venue && (
                          <span className="text-gray-500">
                            Venue: {event.venue}
                          </span>
                        )}
                      </div>
                    </div>
                    <Link
                      to={`/edit-event/${event.id}`} 
                      className="flex items-center space-x-2 text-teal hover:text-mustard transition-colors"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                      <span>Edit</span>
                    </Link>
                  </div>
                </div>
              ))}

              {events.length === 0 && (
                <div className="p-8 text-center text-gray-500">
                  <p>No events created yet.</p>
                  <Link 
                    to="/create-event"
                    className="inline-block mt-4 text-teal hover:text-mustard transition-colors"
                  >
                    Create your first event
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventAdmin;