import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailLink, isSignInWithEmailLink } from 'firebase/auth';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';  // Firestore instance
import { useLocation, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FinishSignUp = () => {
  const [email, setEmail] = useState('');  // Email field for manual entry if not pre-populated
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);  // Track the loading state
  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the URL contains a valid sign-in link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const urlParams = new URLSearchParams(window.location.search);
      const pendingEmail = urlParams.get('email') || window.localStorage.getItem('emailForSignIn');

      if (pendingEmail) {
        handleSignIn(pendingEmail);
      } else {
        setMessage('Please confirm your email address');
      }
    } else {
      setError('Invalid sign-in link.');
    }
  }, [auth, location, navigate]);

  const handleSignIn = async (emailForSignIn) => {
    setLoading(true);  // Set loading to true

    try {
      // Sign in using the provided email and the email link in the URL
      const result = await signInWithEmailLink(auth, emailForSignIn, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      setMessage('Successfully signed in!');
  
      // Fetch ID token for session cookie creation
      const idToken = await result.user.getIdToken();
  
      // Use Firebase Functions to create a secure session cookie
      const functions = getFunctions();
      const createSessionCookie = httpsCallable(functions, 'createSessionCookie');
      const response = await createSessionCookie({ idToken });
  
      if (response.data.sessionCookie) {
        document.cookie = `session=${response.data.sessionCookie}; path=/; Secure; HttpOnly; SameSite=Strict;`;
  
        // Check if the user exists in the 'users' collection by UID
        const userRef = doc(db, 'users', result.user.uid);
        const userDoc = await getDoc(userRef);
  
        if (userDoc.exists()) {
          const { role } = userDoc.data();
          redirectUserBasedOnRole(role);
        } else {
          const pendingUserRef = doc(db, 'pendingUsers', emailForSignIn);
          const pendingUserDoc = await getDoc(pendingUserRef);
  
          if (pendingUserDoc.exists()) {
            const { role } = pendingUserDoc.data();
  
            // Move the user to the main 'users' collection
            await setDoc(doc(db, 'users', result.user.uid), {
              email: result.user.email,
              name: result.user.displayName || 'Anonymous',
              neighborCoinBalance: 0,
              createdAt: new Date(),
              role: role,
            });
  
            await deleteDoc(pendingUserRef);
  
            redirectUserBasedOnRole(role);
          } else {
            setError('Failed to find user role in both collections.');
          }
        }
      } else {
        setError('Failed to create session cookie.');
      }
    } catch (err) {
      const errorCode = err.code;
      let customErrorMessage = '';
      switch (errorCode) {
        case 'auth/invalid-email':
          customErrorMessage = 'Oops! The email address you provided is not an existing user. Please sign up first.';
          break;
        default:
          customErrorMessage = 'An unexpected error occurred. Please try again.';
          break;
      }
      setError(customErrorMessage);
    } finally {
      setLoading(false);  // Ensure loading is set to false after processing
    }
  };

  const redirectUserBasedOnRole = (role) => {
    // Redirect based on the user's role
    if (role === 'eventPlanner') {
      navigate('/event-admin');
    } else if (role === 'businessOwner') {
      navigate('/business-dashboard');
    } else {
      navigate('/dashboard');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loading) {
      handleSignIn(email);  // Call handleSignIn only if not already loading
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Finish Sign Up</h2>
      {message && <p>{message}</p>}
      {error && <p className="text-red-500">{error}</p>}

      {/* Show the email input field if the email is not pre-filled */}
      {!loading && (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg mb-4"
            placeholder="Enter your email"
          />
          <button
            type="submit"
            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
            disabled={loading}  // Disable button when loading
          >
            Submit
          </button>
        </form>
      )}

      {loading && <p className="text-center font-bold">Processing your request, please wait...</p>}
    </div>
  );
};

export default FinishSignUp;