import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import community from '../assets/city_people.png';
import Login from './Login';
import { neighborhoodData } from './Neighborhoods'; // Import neighborhoods data

function Profile() {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch user data from Firestore using UID
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);  // Always use UID as document ID
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setEventTypes(data.eventTypes || []);
            setSelectedNeighborhoods(data.neighborhoods || []);
            setBusinessTypes(data.businessTypes || []);
          } else {
            // If no document exists, create a new one
            const newUserData = {
              displayName: user.displayName,
              email: user.email,
              neighborCoinBalance: 0,
            };
            await setDoc(userRef, newUserData);
            setUserData(newUserData);
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  // Save preferences to Firestore
  const savePreferences = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        await updateDoc(userDoc, {
          eventTypes,
          neighborhoods: selectedNeighborhoods,
          businessTypes,
        });
        setMessage('Preferences saved successfully!');
        setModalOpen(true);
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  };

  const closeModal = () => setModalOpen(false);

  // Handle neighborhood checkbox changes
  const handleNeighborhoodChange = (neighborhood) => {
    setSelectedNeighborhoods((prevSelected) =>
      prevSelected.includes(neighborhood)
        ? prevSelected.filter((n) => n !== neighborhood)
        : [...prevSelected, neighborhood]
    );
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
        <h1 className="text-3xl font-bold text-teal mb-2">Profile Settings</h1>
        <p className="text-gray-600">Manage your preferences and account settings</p>
      </div>

      {!auth.currentUser ? (
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <img src={community} alt="Neighbors gathering" className="mx-auto mb-6 max-w-sm" />
          <p className="text-gray-600 mb-6">Please log in to access your profile settings.</p>
          <Login />
        </div>
      ) : (
        <div className="space-y-6">
          {/* User Info Card */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <div className="flex items-center gap-4 mb-6">
              <div className="flex-shrink-0">
                {userData?.photoURL ? (
                  <img 
                    src={userData.photoURL} 
                    alt="Profile" 
                    className="w-16 h-16 rounded-full"
                  />
                ) : (
                  <div className="w-16 h-16 bg-teal bg-opacity-20 rounded-full flex items-center justify-center">
                    <span className="text-2xl text-teal">{userData?.name?.[0]?.toUpperCase()}</span>
                  </div>
                )}
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900">{userData?.name || 'User'}</h2>
                <p className="text-gray-600">{userData?.email}</p>
              </div>
            </div>
          </div>

          {/* Event Interests Section */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h3 className="text-xl font-semibold text-teal mb-6">Event Interests</h3>
            <div className="grid grid-cols-2 gap-4">
              {['Art', 'Marketplace', 'Music', 'Food'].map((eventType) => (
                <label key={eventType} className="flex items-center space-x-3 p-3 border rounded-lg hover:bg-gray-50">
                  <input
                    type="checkbox"
                    value={eventType}
                    checked={eventTypes.includes(eventType)}
                    onChange={() => setEventTypes((prev) =>
                      prev.includes(eventType) ? prev.filter(e => e !== eventType) : [...prev, eventType]
                    )}
                    className="form-checkbox h-5 w-5 text-teal border-gray-300 rounded focus:ring-teal"
                  />
                  <span className="text-gray-700">{eventType}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Neighborhoods Section */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h3 className="text-xl font-semibold text-teal mb-6">Favorite Miami Neighborhoods</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {Object.values(neighborhoodData.miami).map((neighborhood) => (
                <label key={neighborhood.slug} className="flex items-center space-x-3 p-3 border rounded-lg hover:bg-gray-50">
                  <input
                    type="checkbox"
                    value={neighborhood.name}
                    checked={selectedNeighborhoods.includes(neighborhood.name)}
                    onChange={() => handleNeighborhoodChange(neighborhood.name)}
                    className="form-checkbox h-5 w-5 text-teal border-gray-300 rounded focus:ring-teal"
                  />
                  <span className="text-gray-700">{neighborhood.name}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Business Types Section */}
          <div className="bg-white rounded-lg shadow-sm p-8">
            <h3 className="text-xl font-semibold text-teal mb-6">Business Preferences</h3>
            <div className="grid grid-cols-2 gap-4">
              {['Restaurant', 'Retail', 'Service', 'Entertainment'].map((businessType) => (
                <label key={businessType} className="flex items-center space-x-3 p-3 border rounded-lg hover:bg-gray-50">
                  <input
                    type="checkbox"
                    value={businessType}
                    checked={businessTypes.includes(businessType)}
                    onChange={() => setBusinessTypes((prev) =>
                      prev.includes(businessType) ? prev.filter(b => b !== businessType) : [...prev, businessType]
                    )}
                    className="form-checkbox h-5 w-5 text-teal border-gray-300 rounded focus:ring-teal"
                  />
                  <span className="text-gray-700">{businessType}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Save Button */}
          <div className="flex justify-end">
            <button
              onClick={savePreferences}
              className="bg-teal text-white py-3 px-8 rounded-lg hover:bg-mustard transition-colors"
            >
              Save Changes
            </button>
          </div>

          {/* Success Modal */}
          {modalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
                <h3 className="text-2xl font-bold text-teal mb-4">Success!</h3>
                <p className="text-gray-600 mb-6">{message}</p>
                <button
                  onClick={closeModal}
                  className="w-full bg-teal text-white py-2 rounded-lg hover:bg-mustard transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Profile;