import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // Import FullCalendar component
import dayGridPlugin from '@fullcalendar/daygrid'; // Month/Day view plugin
import interactionPlugin from '@fullcalendar/interaction'; // Plugin for interactions like clicks
import Modal from 'react-modal'; // For modal pop-ups to create events

const EventCalendar = () => {
    const [ events, setEvents ] = useState([]); // State to store events
    const [ modalIsOpen, setModalIsOpen ] = useState(false); // State to toggle modal
    const [selectedDate, setSelectedDate] = useState(''); // State to store selected date
    const [eventTitle, setEventTitle] = useState(''); // State to store event title

    // Function to handle date click
    const handleDateClick = (arg) => {
        setSelectedDate(arg.dateStr);
        setModalIsOpen(true);
    };

    // Handle saving an event
    const handleSaveEvent = () => {
        const newEvent = {
            title: eventTitle,
            date: selectedDate
        };
        setEvents([...events, newEvent]);
        setModalIsOpen(false);
        setEventTitle(''); // Clear the event title after saving      
    };

    return (
        <div>
            <h3 className="text-3xl font-bold text-primary mb-4">Add your upcoming events</h3>
            {/* FullCalendar component */}
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDateClick}
            />
            {/* Modal to create events */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Create Event"
                className="modal"
                overlayClassName="overlay"
            >
                <h2 className="text-2xl font-bold mb-4">Create Event</h2>
                <input
                    type="text"
                    placeholder="Event Title"
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                    className="border border-gray-300 p-2 rounded mb-4 w-full"
                />
                <button
                    onClick={handleSaveEvent}
                    className="bg-primary text-white px-4 py-2 rounded"
                >
                    Save Event
                </button>
            </Modal>
        </div>
    );
}

export default EventCalendar;