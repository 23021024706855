import React, { useState } from 'react';
import axios from 'axios';  // Using Axios to send the request

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    companySize: '',
    budget: '',
    inquiryType: 'general', // default value
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.message || !formData.name) {
      setError('Please fill in the required fields.');
      return;
    }

    try {
      // Replace this URL with your custom webhook URL from Make.com
      const webhookURL = 'https://hook.us1.make.com/xkci4xuk8m4xjxmycw6fl9twuk85x8lw';

      // Send form data to Make.com webhook
      await axios.post(webhookURL, formData);
      
      // After successful submission, reset form and show confirmation message
      setSubmitted(true);
      setFormData({
        name: '',
        email: '',
        companyName: '',
        companySize: '',
        budget: '',
        inquiryType: 'general',
        message: '',
      });

    } catch (err) {
      console.error('Error submitting form:', err);
      setError('Failed to submit the form. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-6">Contact Us</h1>
      {submitted ? (
        <div className="text-green-500">
          <h3>Thank you for reaching out!</h3>
          <p>We will get back to you soon.</p>
        </div>
      ) : (
        <>
          <p className="mb-6">
            Whether you have a general question or are an enterprise looking for a custom solution, we are here to help.
          </p>

          {error && <p className="text-red-500">{error}</p>}

          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Your name"
                required
              />
            </div>

            {/* Email */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Your email"
                required
              />
            </div>

            {/* Inquiry Type */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">I am contacting you for:</label>
              <select
                name="inquiryType"
                value={formData.inquiryType}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              >
                <option value="general">General Inquiry</option>
                <option value="enterprise">Enterprise Solutions</option>
              </select>
            </div>

            {/* Enterprise Fields - only visible if inquiryType is 'enterprise' */}
            {formData.inquiryType === 'enterprise' && (
              <>
                <div className="mb-4">
                  <label className="block text-neutral mb-2">Company Name:</label>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Your company name"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-neutral mb-2">Company Size:</label>
                  <input
                    type="number"
                    name="companySize"
                    value={formData.companySize}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Number of employees"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-neutral mb-2">Budget:</label>
                  <input
                    type="text"
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    placeholder="Budget range"
                  />
                </div>
              </>
            )}

            {/* Message */}
            <div className="mb-4">
              <label className="block text-neutral mb-2">Message:</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                rows="5"
                placeholder="How can we assist you?"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
            >
              Send Message
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default ContactPage;