import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const neighborhoods = [
  'Allapattah', 'Bay Point', 'Bayside', 'Belle Meade', 'Brickell', 'Buena Vista', 
  'Coconut Grove', 'Coral Gables', 'Design District', 'Downtown', 'Edgewater', 
  'El Portal', 'Liberty City', 'Little Haiti', 'Little Havana', 'Little River', 
  'Magnolia Park', 'Midtown', 'Morningside', 'Overtown', 'Palm Grove', 
  'Shorecrest', 'Upper East Side', 'Wynwood'
].sort();

// Utility function to normalize neighborhood names
const normalizeNeighborhoodName = (name) => {
  return name.toLowerCase().replace(/\s+/g, '');
};

const BusinessList = () => {
  const [selectedNeighborhood, setSelectedNeighborhood] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleNeighborhoodChange = (e) => {
    setSelectedNeighborhood(e.target.value);
  };

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        let queryRef = collection(db, 'businesses');
        
        // Apply filter only if a specific neighborhood is selected
        if (selectedNeighborhood) {
          queryRef = query(queryRef, where('neighborhood', '==', selectedNeighborhood));
        }
  
        // Fetch businesses
        const querySnapshot = await getDocs(queryRef);
        let businessList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        // Filter out businesses with the name "Unnamed Business"
        businessList = businessList.filter(business => business.businessName && business.businessName !== 'Unnamed Business');
  
        // Sort businesses alphabetically by businessName
        const sortedBusinessList = businessList.sort((a, b) =>
          a.businessName.localeCompare(b.businessName)
        );
  
        setBusinesses(sortedBusinessList);
      } catch (error) {
        console.error('Error fetching businesses:', error);
      } finally {
        setLoading(false);
      }
    };
  
    // Fetch all businesses if no neighborhood is selected, otherwise fetch filtered businesses
    fetchBusinesses();
  }, [selectedNeighborhood]);

  if (loading) {
    return <p>Loading businesses...</p>;
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">Local Reward Partners</h1>
      
      {/* Neighborhood Filter */}
      <div className="mb-8">
        <select
          onChange={handleNeighborhoodChange}
          value={selectedNeighborhood}
          className="px-4 py-2 border border-gray rounded-lg"
        >
          <option value="">All Neighborhoods</option>
          {neighborhoods.map((neighborhood) => (
            <option key={neighborhood} value={neighborhood}>
              {neighborhood}
            </option>
          ))}
        </select>
      </div>

      {/* Business Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {businesses.length > 0 ? (
          businesses.map((business) => (
            <div key={business.id} className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              {/* Business Logo */}
              {business.logoUrl && (
                <img
                  src={business.logoUrl}
                  alt={`${business.businessName} Logo`}
                  className="w-full h-52 object-fit"
                />
              )}
              <div className="p-4">
                {/* Business Name */}
                <h3 className="text-xl font-semibold mb-2">{business.businessName}</h3>
                
                {/* Business Description */}
                <p className="text-gray-600 mb-4">{business.description}</p>
                
                <div className="flex justify-between items-center">
                  {/* Business Website */}
                  {business.businessWebsite && (
                    <a
                      href={business.businessWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                    >
                      Visit Website
                    </a>
                  )}
                  
                  {/* Neighborhood Name */}
                  {business.neighborhood && (
                    <a
                      href={`/miami/${normalizeNeighborhoodName(business.neighborhood)}`}
                      className="text-primary hover:underline flex items-center"
                    >
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
                      {business.neighborhood}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No businesses found.</p>
        )}
      </div>
    </div>
  );
};

export default BusinessList;