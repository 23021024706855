import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; 
import citypeople from '../assets/city_people.png'; // Adjust the path as necessary

const SponsorForm = ({ eventId }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    industry: '',
    advertisingObjectives: [],
    eventId: eventId, // Include eventId in the form data
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      eventId: eventId,
    }));
  }, [eventId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => {
      const advertisingObjectives = checked
        ? [...prevState.advertisingObjectives, value]
        : prevState.advertisingObjectives.filter((obj) => obj !== value);
      return { ...prevState, advertisingObjectives };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'sponsors'), formData);
      console.log('Document written with ID: ', docRef.id);
      // Optionally, you can reset the form or show a success message here
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div
        className="hero-image"
        style={{
          backgroundImage: `url(${citypeople})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px', // Adjust the height as needed
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <h1 className="hero-text text-3xl font-bold text-white">Become a Sponsor</h1>
      </div>

      <p className="text-lg text-gray-700 mb-4">
        NeighborhoodCoins provides partners with a powerful platform to connect with dedicated locals who are passionate about their communities. By blending in-person and virtual events, digital media, and engaging branded content, NeighborhoodCoins curates neighborhood event lists that inspire meaningful engagement and bring communities together.
      </p>

      <h2 className="text-2xl font-bold mb-4">Sponsor Information</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Industry</label>
          <input
            type="text"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Advertising Objectives</label>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                value="Brand Awareness"
                onChange={handleCheckboxChange}
                className="form-checkbox"
              />
              <span className="ml-2">Brand Awareness</span>
            </label>
          </div>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                value="Lead Generation"
                onChange={handleCheckboxChange}
                className="form-checkbox"
              />
              <span className="ml-2">Lead Generation</span>
            </label>
          </div>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                value="Sales"
                onChange={handleCheckboxChange}
                className="form-checkbox"
              />
              <span className="ml-2">Sales</span>
            </label>
          </div>
        </div>
        <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight">
          Submit
        </button>
      </form>
    </div>
  );
};

export default SponsorForm;