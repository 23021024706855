import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';  // Firebase instance
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const MyOffers = () => {
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          // Query to fetch offers by businessId (assuming the businessId is the user.uid)
          const offersQuery = query(collection(db, 'offers'), where('businessId', '==', user.uid));
          const querySnapshot = await getDocs(offersQuery);
          const offersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setOffers(offersList);
        }
      } catch (err) {
        setError('Failed to fetch offers: ' + err.message);
      }
    };

    fetchOffers();
  }, []);

  return (
    <div className="my-offers-section">
      <h3 className="text-xl font-semibold mb-4">Offer Event Attendees rewards from your business</h3>
      {error && <p className="text-red-500">{error}</p>}
      {offers.length === 0 ? (
        <p>No offers found. </p>
      ) : (
        <ul className="list-disc list-inside">
          {offers.map((offer) => (
            <li key={offer.id}>
              <p className="font-semibold">{offer.offerTitle}</p>
              <p>{offer.offerDescription}</p>
              <p>NeighborhoodCoins: {offer.coinOffer}</p>
              {/* <p>Expires: {new Date(offer.expiryDate.seconds * 1000).toLocaleDateString()}</p> */}
              <Link to={`/edit-offer/${offer.id}`} className="text-primary hover:underline">Edit Offer</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyOffers;