import React from 'react';

const WelcomeMessage = ({ auth, userEmail, userRole, roleDisplayNames, getDashboardLink }) => {
   
    return (
    <div className="text-center bg-mustard mb-5 py-5 px-5">
      <h2 className="text-2xl font-bold mb-3">Welcome Back!</h2>
      <p className="text-center">
        You can manage your account by visiting your{' '} 
        <a href={getDashboardLink()} className="text-primary hover:underline">
          {roleDisplayNames[userRole]} Dashboard
        </a>.
      </p>
    </div>
  );
};

export default WelcomeMessage;