import React from 'react';
import { Link, useNavigate } from 'react-router-dom';


const PricingPage = () => {
  const pricingPlans = [
    {
        title: 'Basic',
        price: 'Free',
        features: [
          'List 1 event or business (Event Planners & Business Owners)',
          'Basic event management and analytics',
          'Profile listing (all users)',
          'Attend events and earn rewards (Attendees)',
        ],
        cta: 'Get Started',
        ctaLink: '/signup', // Link to sign up
      },
    {
      title: 'Pro',
      price: '9.99 USD',
      features: [
        'Unlimited event/business listings',
        'Priority search placement',
        'Offer more rewards/coins',
        'Advanced analytics',
        'AI credits for promotions',
      ],
      cta: 'Join the Community',
      ctaLink: '/signup-pro', // Link for Pro sign up
    },
    {
      title: 'Enterprise',
      price: '49.99 USD',
      features: [
        'Custom integrations',
        'Dedicated support',
        'Advanced reporting & analytics',
        'Unlimited featured spots & ads',
        'Exclusive promotional campaigns',
      ],
      cta: 'Contact Us',
      ctaLink: '/contact', // Contact form link for enterprise users
    },
  ];
  const planBackgroundColors = {
    Basic: 'bg-white',
    Pro: 'bg-tealLight',
    Enterprise: 'bg-lightPurple'
  };
  return (
    <div className="max-w-6xl mx-auto py-12 px-4">
      <h1 className="text-4xl font-bold text-center mb-8">Pricing Plans</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {pricingPlans.map((plan, index) => (
          <div key={index} 
          className={`shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 ${planBackgroundColors[plan.title] || 'bg-white'}`}
>
            <h2 className="text-2xl font-bold text-center mb-4">{plan.title}</h2>
            <p className="text-center text-xl font-bold mb-4">{plan.price} <small>per month</small></p>
            <ul className="mb-6">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="text-gray py-1">• {feature}</li>
              ))}
            </ul>
            <Link
              to={plan.ctaLink}
              className="block text-center bg-mustard text-white py-2 px-4 rounded-lg font-bold hover:bg-coral transition-colors"
            >
              {plan.cta}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPage;