import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../firebase'; // Import Firebase instance, including storage
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage methods
import { useLocation, useNavigate } from 'react-router-dom';

const CreateOffer = () => {
  const [formData, setFormData] = useState({
    offerTitle: '',
    offerDescription: '',
    discountPercentage: '',
    offerImage: null,
    coinOffer: 0,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
  const location = useLocation();
  const navigate = useNavigate();
  const [businessData, setBusinessData] = useState(location.state?.businessData || null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, offerImage: file });
  };

  useEffect(() => {
    const fetchBusinessData = async () => {
      const user = auth.currentUser;
      if (user && !businessData) {
        try {
          const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
          if (businessDoc.exists()) {
            setBusinessData(businessDoc.data());
          } else {
            setError('Please set up your business profile first.');
          }
        } catch (err) {
          setError('There was an error fetching business data: ' + err.message);
        }
      }
    };
    fetchBusinessData();
  }, [businessData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = auth.currentUser;

    // Validate if businessData and user are available
    if (!businessData || !user) {
      setError('You need to be logged in to create an offer.');
      setLoading(false); // Stop loading
      return;
    }

    // Validate coin offer range
    if (formData.coinOffer < 1 || formData.coinOffer > 20) {
      setError('Please enter a NeighborhoodCoin value between 1 and 20.');
      setLoading(false); // Stop loading
      return;
    }

    try {
      let offerImageUrl = '';

      // Upload the image if an offer image is provided
      if (formData.offerImage) {
        const imageRef = ref(storage, `offers/${user.uid}/${formData.offerImage.name}`);
        const uploadTask = uploadBytesResumable(imageRef, formData.offerImage);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            setError('Failed to upload image: ' + error.message);
            setLoading(false); // Stop loading if error occurs
            return;
          },
          async () => {
            offerImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
            await createOfferInFirestore(offerImageUrl);
          }
        );
      } else {
        // No image upload, proceed with offer creation
        await createOfferInFirestore(offerImageUrl);
      }
    } catch (err) {
      setError(`Failed to create offer: ${err.message}`);
      setLoading(false);
    }
  };

  const createOfferInFirestore = async (offerImageUrl) => {
    try {
      await addDoc(collection(db, 'offers'), {
        offerTitle: formData.offerTitle,
        offerDescription: formData.offerDescription,
        coinOffer: formData.coinOffer,
        offerImageUrl, // Image URL from Firebase Storage (if uploaded)
        businessId: auth.currentUser.uid,
        createdAt: new Date(),
      });

      setMessage('Offer created successfully!');
      setLoading(false);
      navigate('/business-dashboard'); // Redirect after successful offer creation
    } catch (err) {
      setError(`Error saving offer in Firestore: ${err.message}`);
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Create a New Offer</h2>
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Offer Title:</label>
          <input
            type="text"
            name="offerTitle"
            value={formData.offerTitle}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Offer Description:</label>
          <textarea
            name="offerDescription"
            value={formData.offerDescription}
            placeholder="Discounts, deals, etc."
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Upload Offer Image:</label>
          <input
            type="file"
            name="offerImage"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
          {uploadProgress > 0 && (
            <p className="mt-2 text-sm text-blue-500">Upload progress: {uploadProgress}%</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">NeighborhoodCoins Customer Needs to Redeem Offer:</label>
          <input
            type="number"
            name="coinOffer"
            placeholder="Enter a number between 1 and 20"
            value={formData.coinOffer}
            min="1"
            max="20"
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
          disabled={loading}
        >
          {loading ? 'Creating Offer...' : 'Create Offer'}
        </button>
      </form>
    </div>
  );
};

export default CreateOffer;