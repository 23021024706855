import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../firebase'; // Import Firebase instance
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage methods
import { useNavigate } from 'react-router-dom';

const EditBusinessProfile = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    location: '',
  });
  const [logoFile, setLogoFile] = useState(null); // For storing the new logo file
  const [logoPreview, setLogoPreview] = useState(''); // For previewing the selected logo
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
          if (businessDoc.exists()) {
            const businessData = businessDoc.data();
            setFormData({
              businessName: businessData.businessName || '',
              location: businessData.location || '',
              businessWebsite: businessData.businessWebsite || '',
            });
            setLogoPreview(businessData.logoUrl || ''); // Display current logo if exists
          } else {
            setError('No business data found.');
          }
        } catch (err) {
          setError(err.message);
        }
      }
    };

    fetchBusinessData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
    setLogoPreview(URL.createObjectURL(file)); // Create a preview URL for the selected logo
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = auth.currentUser;

    try {
      let logoUrl = logoPreview; // Use the existing logo URL by default

      // If a new logo file is selected, upload it
      if (logoFile) {
        const logoRef = ref(storage, `business_logos/${user.uid}/${logoFile.name}`);
        const uploadTask = uploadBytesResumable(logoRef, logoFile);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => reject(error),
            async () => {
              logoUrl = await getDownloadURL(uploadTask.snapshot.ref); // Get the download URL after upload
              resolve();
            }
          );
        });
      }

      // Update Firestore with the new business name, location, and logo URL
      const businessRef = doc(db, 'businesses', user.uid);
      await updateDoc(businessRef, {
        businessName: formData.businessName,
        location: formData.location,
        businessWebsite: formData.businessWebsite,
        logoUrl, // Update logo URL if it has changed
        updatedAt: new Date(),
      });

      setMessage('Business profile updated successfully!');
      navigate('/business-dashboard');
    } catch (err) {
      setError(`Failed to update profile: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Edit Business Profile</h2>
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Business Name:</label>
          <input
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
      
        <div className="mb-4">
          <label className="block text-neutral mb-2">Business Website:</label>
          <input
            type="url"
            name="businessWebsite"
            value={formData.businessWebsite}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Business Address:</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Logo Upload Section */}
        <div className="mb-4">
          <label className="block text-neutral mb-2">Business Logo:</label>
          {logoPreview && (
            <div className="mb-4">
              <img src={logoPreview} alt="Business Logo Preview" className="w-24 h-24 object-cover rounded-full" />
            </div>
          )}
          <input type="file" onChange={handleLogoChange} className="w-full px-4 py-2 border border-gray-300 rounded-lg" />
        </div>

        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Profile'}
        </button>
      </form>
    </div>
  );
};

export default EditBusinessProfile;