import React, { useState } from 'react';
import axios from 'axios';

const MailchimpSubscribe = () => {
  const [email, setEmail] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [status, setStatus] = useState('');

  const neighborhoods = [
    'Allapattah', 'Brickell', 'Buena Vista',
    'Coconut Grove',
    'Coral Gables',
    'Design District',
    'Downtown Miami',
    'Edgewater',
    'Little Haiti',
    'Little Havana',
    'Little River',
    'Miami Beach',
    'Midtown',
    'Overtown',
    'South Beach',
    'Wynwood',
    'All Neighborhoods'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    // Use environment variables for the API URL
    const apiUrl = process.env.NODE_ENV === 'production' 
      ? process.env.REACT_APP_API_URL_PRODUCTION 
      : process.env.REACT_APP_API_URL_DEVELOPMENT;

    console.log('API URL:', apiUrl); // Debugging line

    try {
      const response = await axios.post(apiUrl, {
        email,
        neighborhood
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Subscription response:', response.data);

      if (response.data.status === 'success') {
        setEmail('');
        setNeighborhood('');
        setStatus('success');
      } else {
        throw new Error(response.data.message || 'Subscription failed');
      }
    } catch (error) {
      console.error('Subscription error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setStatus('error');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg">
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          className="w-full p-2 mb-4 border rounded"
        />
        <select
          value={neighborhood}
          onChange={(e) => setNeighborhood(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="">Select Neighborhood</option>
          {neighborhoods.map(n => (
            <option key={n} value={n}>{n}</option>
          ))}
        </select>
        <button 
          type="submit"
          disabled={status === 'sending'}
          className="w-full bg-teal text-white p-2 rounded"
        >
          {status === 'sending' ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
      {status === 'success' && <p className="text-green-600 mt-2">Thanks for subscribing!</p>}
      {status === 'error' && <p className="text-red-600 mt-2">Failed to subscribe. Please try again.</p>}
    </div>
  );
};

export default MailchimpSubscribe;