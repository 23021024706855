import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Azure OpenAI configuration
const AZURE_OPENAI_ENDPOINT = process.env.REACT_APP_AZURE_OPENAI_ENDPOINT;
const DEPLOYMENT_NAME = process.env.REACT_APP_AZURE_OPENAI_DEPLOYMENT_NAME;
const API_VERSION = "2024-02-15-preview";
const API_KEY = process.env.REACT_APP_AZURE_OPENAI_KEY;

const BusinessPromo = () => {
  const [businessName, setBusinessName] = useState('');
  const [product, setProduct] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [generatedText, setGeneratedText] = useState('');
  const [remainingUsage, setRemainingUsage] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const USAGE_LIMIT = 10;  

  // Fetch user data to check usage limit
  useEffect(() => {
    const fetchUserUsage = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRemainingUsage(userData.aiUsageCount ?? USAGE_LIMIT);
        } else {
          await setDoc(userDocRef, { aiUsageCount: 0, plan: 'free' });
          setRemainingUsage(USAGE_LIMIT);
        }
      }
    };

    fetchUserUsage();
  }, []);

  const generateAIContent = async (prompt) => {
    const endpoint = `${AZURE_OPENAI_ENDPOINT}/openai/deployments/${DEPLOYMENT_NAME}/chat/completions?api-version=${API_VERSION}`;
  
    try {
      const response = await axios.post(
        endpoint,
        {
          prompt: [
            { role: "system", content: "You are a marketing expert who creates compelling promotional content." },
            { role: "user", content: prompt }
          ],
          max_tokens: 800,
          temperature: 0.7,
          model: DEPLOYMENT_NAME
        },
        {
          headers: {
            "api-key": API_KEY,
            "Content-Type": "application/json"
          }
        }
      );
      if (response.data?.choices?.[0]?.message?.content) {
        return response.data.choices[0].message.content;
      } else {
        throw new Error('Unexpected API response structure');
      }
    } catch (error) {
      console.error('Azure OpenAI API Error:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
      });
      throw error; // Re-throw the error after logging it
    }
  };


  const handleGenerateText = async () => {
    const user = auth.currentUser;
    if (!user) {
      setError('Please log in to use this feature');
      return;
    }
    if (remainingUsage <= 0) {
      setError('You have reached your usage limit. Please upgrade your plan to continue using this feature.');
      return;
    }
    if (!businessName || !product || !targetAudience) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const generatedText = await generateAIContent(
        `Create a promotional text for ${businessName}, promoting their ${product}. Target audience: ${targetAudience}.`
      );
      setGeneratedText(generatedText);

      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const currentUsage = userDoc.data().aiUsageCount ?? 0;

      await updateDoc(userDocRef, {
        aiUsageCount: currentUsage + 1
      });
      setRemainingUsage(remainingUsage - 1);
    } catch (error) {
      console.error('Error generating promotional text:', error);
      setError('Failed to generate promotional text. Please check your API configuration.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpgrade = () => {
    navigate('/pricing');
  };


  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Generate Promotional Content for Your Business</h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Business Name</label>
        <input
          type="text"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Enter your business name"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Product</label>
        <input
          type="text"
          value={product}
          onChange={(e) => setProduct(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Enter your product name"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Target Audience</label>
        <input
          type="text"
          value={targetAudience}
          onChange={(e) => setTargetAudience(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Describe your target audience"
        />
      </div>

      <button
        onClick={handleGenerateText}
        disabled={loading}
        className={`${
          loading ? 'bg-coral' : 'bg-mustard hover:bg-accent'
        } text-white font-bold py-2 px-4 rounded transition-colors`}
      >
        {loading ? 'Generating...' : 'Generate Text'}
      </button>

      {generatedText && (
        <div className="mt-6 p-4 bg-gray-100 rounded">
          <h3 className="text-xl font-bold mb-2">Generated Promotional Text</h3>
          <p className="whitespace-pre-wrap">{generatedText}</p>
        </div>
      )}
    </div>
  );
};

export default BusinessPromo;