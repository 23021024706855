import React, { useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import useAuthState from '../hooks/useAuthState';
import GoogleButton from '../components/GoogleButton';

const SignupPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAuthState(auth);  // Custom hook to check auth status

  useEffect(() => {
    if (isAuthenticated) {
      // If the user is authenticated, redirect to their dashboard or role selection page
      navigate('/dashboard'); // Or role selection page if needed
    }
  }, [isAuthenticated, navigate]);

  const handleGoogleSignUp = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/dashboard'); // Redirect after signup
    } catch (error) {
      console.error('Error during sign-up:', error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Sign Up</h2>
      <p className="text-center mb-4">
        Join NeighborhoodCoins and start earning rewards today!
      </p>
      <div className="text-center">
        <GoogleButton onClick={handleGoogleSignUp} />
        
        {/* Optionally include email signup form here */}
      </div>
    </div>
  );
};

export default SignupPage;