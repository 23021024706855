import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import Map from '../components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';
import CoinImage from '../assets/coin_blank.png';
import { Helmet } from 'react-helmet-async';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { AuthContext } from '../context/AuthContext';
import SponsorForm from '../components/SponsorForm';
import Banner from '../assets/wynwoodaerial.png';
import { formatDate, formatTime } from '../utils/dateUtils';  
const EventDetails = () => {
  const {isAuthenticated, user} = useContext(AuthContext);
  const shareUrl = window.location.href;  // Current page URL for sharing
  const { eventId } = useParams();  // Get the event ID from the URL
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false); // Check-in state
  const [showSignup, setShowSignup] = useState(false); // Show or hide sign-up form
  const [isSponsored, setIsSponsored] = useState(false); // Track sponsorship
  const [message, setMessage] = useState('');

  const formatEventDate = (event) => {
    if (!event.date) return '';
    
    const startDate = event.date.toDate ? event.date.toDate() : new Date(event.date);
    let dateStr = startDate.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

    if (event.endDate) {
      const endDate = event.endDate.toDate ? event.endDate.toDate() : new Date(event.endDate);
      dateStr += ` - ${endDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })}`;
    }

    return dateStr;
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);
  
        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          setEvent(eventData);
          setIsSponsored(!!eventData.sponsoredBy); 
          setRsvpStatus(eventData.rsvps && user && eventData.rsvps.includes(user?.email));
  
        } else {
          setError('Event not found');
        }
      } catch (err) {
        setError('Failed to fetch event details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchEvent();
  }, [eventId, user]);

  const handleSponsorEvent = async () => {
    const user = auth.currentUser;
    if (!user) {
      setError('You need to be logged in to sponsor an event.');
      return;
    }
  
    try {
      const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
      if (!businessDoc.exists()) {
        setError('Business profile not found.');
        return;
      }
  
      const businessData = businessDoc.data();
      const eventRef = doc(db, 'events', eventId);
  
      // Use arrayUnion to add the sponsor object without overwriting existing sponsors
      await updateDoc(eventRef, {
        sponsoredBy: arrayUnion({
          sponsorId: user.uid,
          sponsorName: businessData.businessName,
        }),
      });
  
      setIsSponsored(true);
      setMessage('You have successfully sponsored this event!');
    } catch (error) {
      setError(`Failed to sponsor event: ${error.message}`);
    }
  };

  const handleRsvp = async () => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpStatus(true);
      setMessage(`You have successfully RSVP\'d to this event! You will receive your ${event.coins} NeighborhoodCoins when you Check in to the event.`); // Add success message
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const handleCheckIn = async () => {
    if (!user) {
      setShowSignup(true);  // Show sign-up form if user is not authenticated
      return;
    }
  
    try {
      // Create a unique check-in ID for this event and user
      const checkInRef = doc(db, 'check-ins', `${eventId}_${user.uid}`);
      
      // Store the check-in details in Firestore
      await setDoc(checkInRef, {
        eventId: eventId,
        userId: user.uid,
        checkInTime: new Date(), // Store the current time
      });
  
      const eventRef = doc(db, 'events', eventId);
      const userRef = doc(db, 'users', user.uid);
  
      // Mark the user as checked in by adding their email to the event's `checkIns` array
      await updateDoc(eventRef, {
        checkIns: arrayUnion(user.email),  // Add user email to check-ins
      });
  
      // Add NeighborhoodCoins to the user's balance
      await updateDoc(userRef, {
        neighborCoinBalance: increment(event.coins || 0), // Increment balance by event's coin value
      });
  
      setCheckedIn(true); // Update state to reflect successful check-in
      setMessage(`You have successfully checked in and earned ${event.coins || 0} NeighborhoodCoins!`);
    } catch (err) {
      setError('Failed to check in: ' + err.message);
    }
  };

  const isEventActive = (eventDate, eventStartTime, eventEndTime) => {
    const currentTime = new Date();
  
    // Check if eventStartTime and eventEndTime are strings before trying to split
    if (typeof eventStartTime === 'string' && typeof eventEndTime === 'string') {
      const eventStartDateTime = new Date(eventDate.toDate());
      eventStartDateTime.setHours(...eventStartTime.split(':').map(Number));
  
      const eventEndDateTime = new Date(eventDate.toDate());
      eventEndDateTime.setHours(...eventEndTime.split(':').map(Number));
  
      return currentTime >= eventStartDateTime && currentTime <= eventEndDateTime;
    } else {
      console.error('Invalid time format for event start or end time');
      return false;
    }
  };
  const closeModal = () => {
    setShowSignup(false);
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });

  const eventDateString = event && new Date(event.date).toLocaleDateString('en-US', {
    weekday:'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });
  const normalizedNeighborhood = (neighborhood) => {
    return neighborhood.toLowerCase().replace(/\s+/g, '');
  }
  const handleSponsorClick = () => {
    navigate(`/sponsor?eventId=${eventId}`);
  };
  return (
    <div className="min-h-screen bg-neutralLight">
      <div 
        className="relative bg-teal py-24 mb-12"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 128, 128, 0.7), rgba(0, 128, 128, 0.9)), url(${Banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-teal bg-opacity-70"></div>
        <div className="container mx-auto px-4 relative">
          <h2 className="font-display text-4xl font-bold text-white text-center">
            {event.name}
          </h2>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {event.imageUrl && (
            <img 
              src={event.imageUrl} 
              alt={event.name} 
              className="w-full h-64 object-cover"
            />
          )}
          <div className="p-6">
            <h1 className="text-3xl font-bold text-teal mb-4">{event.name}</h1>
            
            {/* Updated Date and Time Section */}
            <div className="text-gray-600 mb-6">
              {formatEventDate(event)}
              {event.startTime && ` at ${formatTime(event.startTime)}`}
{event.endTime && ` - ${formatTime(event.endTime)}`}
{event.doorsOpenTime && ` (Doors: ${formatTime(event.doorsOpenTime)})`}
            </div>
            
            {/* Updated Location Section with Hierarchy and Neighborhood Link */}
            <div className="bg-gray-50 p-4 rounded-lg mb-6">
              <div className="text-xl font-semibold text-teal mb-4">Location</div>
              <div className="space-y-2">
                <div className="text-xl font-bold">
                  <Link 
                    to={`/miami/${normalizedNeighborhood(event.neighborhood)}`}
                    className="text-mustard hover:text-teal transition-colors"
                  >
                    {event.neighborhood || 'Miami'}
                  </Link>
                </div>
                <div className="text-lg text-teal font-semibold">
                  {event.venue}
                </div>
                <div className="text-gray-600">
                  {event.location}
                </div>
              </div>
            </div>

            <div className="text-gray-600 mb-6">{event.description}</div>

            {/* Coins and Rewards Section */}
            <div className="flex items-center justify-between mb-6">
              <div className="coin-display flex items-center bg-teal bg-opacity-10 rounded-full px-4 py-2">
                <img src={CoinImage} alt="coins" className="w-6 h-6 mr-2" />
                <span className="font-bold text-teal">+{event.coins}</span>
              </div>
              {event.reward && (
                <div className="text-mustard font-semibold">
                  Extra Reward: {event.reward}
                </div>
              )}
            </div>

            {/* Ticket URL Button */}
            {event.ticketUrl && (
              <div className="mb-6">
                <a
                  href={event.ticketUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block w-full bg-mustard text-white text-center py-3 px-6 rounded-lg hover:bg-teal transition-colors"
                >
                  Purchase Tickets / RSVP
                </a>
                <p className="text-sm text-gray-600 text-center mt-2">
                  *Opens event organizer's ticketing page in new tab
                </p>
              </div>
            )}

            {/* Map Section */}
            <div className="mt-6 rounded-lg overflow-hidden">
              <Map event={event} />
            </div>

            {/* Update RSVP Section */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8">
              {!rsvpStatus ? (
                <div className="flex flex-col items-center">
                  <button
                    onClick={handleRsvp}
                    className="bg-teal text-white py-3 px-8 rounded-lg hover:bg-mustard transition-colors mb-2"
                  >
                    Earn {event.coins} Coins
                  </button>
                  <p className="text-sm text-gray-600 text-center">
                    *This only confirms coin earnings. For event tickets/RSVP, visit the event organizer's website.
                  </p>
                </div>
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg text-center">
                  <p className="text-teal font-semibold">You're registered to earn coins!</p>
                  {!checkedIn && (
                    <div>
                      <button
                        onClick={handleCheckIn}
                        className="mt-2 bg-mustard text-white py-2 px-6 rounded-lg hover:bg-teal transition-colors"
                      >
                        Check In to Collect Coins
                      </button>
                      <p className="text-sm text-gray-600 mt-2">
                        Check in at the event to receive your coins
                      </p>
                    </div>
                  )}
                </div>
              )}
              {message && (
                <div className="bg-teal bg-opacity-10 p-4 rounded-lg">
                  <p className="text-teal">{message}</p>
                </div>
              )}
            </div>

            {/* Social Share Section */}
            <div className="mt-8 flex gap-4 justify-center">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <EmailShareButton url={shareUrl}>
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </div>

      {/* Add Sign Up Modal */}
      {showSignup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
            <h3 className="text-2xl font-bold text-teal mb-6">Please Sign Up to RSVP</h3>
            <GoogleSignInButton />
            <button
              onClick={() => setShowSignup(false)}
              className="mt-4 w-full bg-gray-200 text-gray-700 py-2 rounded-lg hover:bg-gray-300 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;