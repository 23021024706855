import { Link } from 'react-router-dom';
import LittleHaitiBanner from '../assets/LittleHaiti_flags.png';
import WynwoodBanner from '../assets/wynwood.png';
import DesignDistrictBanner from '../assets/designdistrict.png';
import OvertownBanner from '../assets/overtown.png';
import Miami from '../assets/miami.png';
import BuenaVista from '../assets/buenavista.png';
import LittleHavana from '../assets/littlehavana.png';

export const neighborhoodData = {
  miami: {
    littlehaiti: {
      name: 'Little Haiti',
      slug: 'littlehaiti',
      description: 'Little Haiti is a vibrant neighborhood in Miami, Florida. It is known for its rich Haitian culture and history. The neighborhood is home to many Haitians and has a strong sense of community. Little Haiti is a great place to explore if you are interested in learning more about Haitian culture and traditions.',
      headerImage: LittleHaitiBanner,
      center: { lat: 25.8376, lng: -80.1983 },
      boundaryCoordinates: [[25.832, -80.205], [25.832, -80.191], [25.818, -80.191], [25.818, -80.205]],
    },
    wynwood: {
      name: 'Wynwood',
      slug: 'wynwood',
      description: 'Wynwood is a neighborhood in Miami, Florida known for its vibrant street art, galleries, and trendy shops. It is a popular destination for art enthusiasts and tourists.',
      headerImage: WynwoodBanner,
      center: { lat: 25.8007, lng: -80.1998 },
      boundaryCoordinates: [[25.805, -80.205], [25.805, -80.195], [25.795, -80.195], [25.795, -80.205]],
    },
    designdistrict: {
      name: 'Design District',
      slug: 'designdistrict',
      description: 'The Design District is a neighborhood in Miami, Florida known for its high-end fashion stores, art galleries, and restaurants. It is a hub for luxury shopping and dining.',
      headerImage: DesignDistrictBanner,
      center: { lat: 25.8136, lng: -80.1917 },
      boundaryCoordinates: [[25.818, -80.195], [25.818, -80.188], [25.809, -80.188], [25.809, -80.195]],
    },
    overtown: {
      name: 'Overtown',
      slug: 'overtown',
      description: 'Overtown is a historic neighborhood in Miami, Florida known for its rich African-American culture and history. It is home to many cultural landmarks and events.',
      headerImage: OvertownBanner,
      center: { lat: 25.7831, lng: -80.2044 },
      boundaryCoordinates: [[25.788, -80.210], [25.788, -80.200], [25.778, -80.200], [25.778, -80.210]],
    },
    miamibeach: {
      name: 'Miami Beach',
      slug: 'miamibeach',
      description: 'Miami Beach is a popular tourist destination in Miami, Florida known for its beautiful beaches, vibrant nightlife, and art deco architecture. It is a great place to relax and enjoy the sun.',
      headerImage: Miami,
      center: { lat: 25.7907, lng: -80.1300 },
      boundaryCoordinates: [[25.795, -80.135], [25.795, -80.125], [25.785, -80.125], [25.785, -80.135]],
    },
    buenavista: {
      name: 'Buena Vista',
      slug: 'buenavista',
      description: 'Buena Vista is a neighborhood in Miami, Florida known for its historic homes, art galleries, and boutiques. It is a great place to explore if you are interested in architecture and design.',
      headerImage: BuenaVista,
      center: { lat: 25.8136, lng: -80.1917 },
      boundaryCoordinates: [[25.818, -80.195], [25.818, -80.188], [25.809, -80.188], [25.809, -80.195]],
    },
    littlehavana: {
      name: 'Little Havana',
      slug: 'littlehavana',
      description: 'Little Havana is a neighborhood in Miami, Florida known for its Cuban culture, music, and food. It is a vibrant and colorful neighborhood with many cultural attractions.',
      headerImage: LittleHavana,
      center: { lat: 25.7776, lng: -80.2376 },
      boundaryCoordinates: [[25.782, -80.243], [25.782, -80.233], [25.772, -80.233], [25.772, -80.243]],
    },
    littleriver: {  
      name: 'Little River',
      slug: 'littleriver',
      description: 'Little River is a neighborhood in Miami, Florida known for its diverse community and vibrant arts scene. It is home to many art galleries, studios, and creative spaces.',
      headerImage: Miami,
      center: { lat: 25.8421, lng: -80.1922 },
      boundaryCoordinates: [[25.847, -80.198], [25.847, -80.188], [25.837, -80.188], [25.837, -80.198]],
    },
    downtown: {
      name: 'Downtown Miami',
      slug: 'downtown',
      description: 'Downtown Miami is the bustling heart of the city, known for its skyscrapers, cultural institutions, and vibrant nightlife. It is a hub for business, entertainment, and tourism.',
      headerImage: Miami,
      center: { lat: 25.7743, lng: -80.1937 },
      boundaryCoordinates: [[25.779, -80.198], [25.779, -80.188], [25.769, -80.188], [25.769, -80.198]],
    },
    miamigardens: {
      name: 'Miami Gardens',
      slug: 'miamigardens',
      description: 'Miami Gardens is a suburban neighborhood in Miami, Florida known for its family-friendly community, parks, and schools. It is a great place to raise a family and enjoy outdoor activities.',
      headerImage: Miami,
      center: { lat: 25.9420, lng: -80.2456 },
      boundaryCoordinates: [[25.947, -80.250], [25.947, -80.240], [25.937, -80.240], [25.937, -80.250]],
    },
  },
};