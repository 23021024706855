import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <p><strong>Effective Date:</strong> October 17, 2024</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
      <p>
        Welcome to NeighborhoodCoins, owned and operated by <strong>Unieros LLC</strong> ("we," "us," or "our"). 
        At <strong>Unieros LLC</strong>, we are committed to protecting your privacy and personal information. 
        This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Information We Collect</h2>
      <p>
       <ul>
        <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and profile information.</li>
        <li> <strong>Automatically Collected Information:</strong> We may collect information such as device information, usage data, and IP addresses.</li>
        </ul>
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. How We Use Your Information</h2>
      <p>
        We use the information we collect in the following ways:
        <ul className="list-disc list-inside ml-4">
          <li>To provide and maintain our services.</li>
          <li>To communicate with you about updates, events, or offers.</li>
          <li>To enhance user experience and improve the app's functionality.</li>
          <li>To fulfill legal obligations and for security purposes.</li>
        </ul>
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal information. However, we may share your information:
        <ul className="list-disc list-inside ml-4">
          <li>With third-party service providers that help us operate our app.</li>
          <li>When required by law or to protect the rights and safety of our users.</li>
          <li>With affiliates or subsidiaries within <strong>Unieros LLC</strong>.</li>
        </ul>
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Your Choices</h2>
      <p>
        You can update your account information or delete your account at any time by contacting us at webmaster@unieros.com.
        Please note that some information may be retained to comply with legal obligations.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Security</h2>
      <p>We implement reasonable security measures to protect your personal information.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Children’s Privacy</h2>
      <p>Our app is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under 13.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">8. Changes to this Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:<br />
        <strong>Unieros LLC</strong><br />
        1656 Ware Ave, #251, East Point, GA 30344<br />
        webmaster@unieros.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;