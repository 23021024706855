import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase'; // Firebase instance
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

const EditOffer = () => {
  const { offerId } = useParams();  // Get the offerId from the URL parameters
  const [formData, setFormData] = useState({
    offerTitle: '',
    offerDescription: '',
    coinOffer: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOfferData = async () => {
      if (!auth.currentUser) {
        setError('User not authenticated');
        return;
      }

      try {
        // Fetch the offer using the offerId from Firestore
        const offerRef = doc(db, 'offers', offerId);
        const offerDoc = await getDoc(offerRef);

        if (offerDoc.exists()) {
          const offerData = offerDoc.data();
          setFormData({
            offerTitle: offerData.offerTitle || '',
            offerDescription: offerData.offerDescription || '',
            coinOffer: offerData.coinOffer || '',
          });
        } else {
          setError('Offer not found');
        }
      } catch (err) {
        setError(`Failed to fetch offer: ${err.message}`);
      }
    };

    fetchOfferData();
  }, [offerId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Update the offer details in Firestore
      const offerRef = doc(db, 'offers', offerId);
      await updateDoc(offerRef, {
        offerTitle: formData.offerTitle,
        offerDescription: formData.offerDescription,
        coinOffer: formData.coinOffer,
        updatedAt: new Date(),
      });

      setMessage('Offer updated successfully!');
      navigate('/business-dashboard');
    } catch (err) {
      setError(`Failed to update offer: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Edit Offer</h2>
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Offer Title:</label>
          <input
            type="text"
            name="offerTitle"
            value={formData.offerTitle}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">Offer Description:</label>
          <textarea
            name="offerDescription"
            value={formData.offerDescription}
            placeholder="Discounts, deals, etc."
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-neutral mb-2">NeighborhoodCoins Customer Needs to Redeem Offer:</label>
          <input
            type="number"
            name="coinOffer"
            value={formData.coinOffer}
            min="1"
            max="20"
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
          disabled={loading}
        >
          {loading ? 'Updating Offer...' : 'Update Offer'}
        </button>
      </form>
    </div>
  );
};

export default EditOffer;