import React, { useState, useEffect } from 'react';
import { CreditCard, BarChart3, Settings, Upload } from 'lucide-react';
import { auth, db, storage } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

// Mock data for demonstration
const SPONSORSHIP_TIERS = {
  basic: {
    name: 'Basic',
    price: 500,
    features: ['Small logo on page', 'Basic analytics', '1 week duration'],
    stripeProductId: 'price_basic123'
  },
  featured: {
    name: 'Featured',
    price: 1000,
    features: ['Prominent banner placement', 'Advanced analytics', '2 weeks duration'],
    stripeProductId: 'price_featured456'
  },
  exclusive: {
    name: 'Exclusive',
    price: 2000,
    features: ['Full-page takeover', 'Premium analytics', '1 month duration'],
    stripeProductId: 'price_exclusive789'
  }
};

const SponsorshipDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [sponsorData, setSponsorData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Check authentication and fetch sponsor data
  useEffect(() => {
    const checkAuth = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const sponsorRef = doc(db, 'sponsors', user.uid);
        const sponsorDoc = await getDoc(sponsorRef);

        if (sponsorDoc.exists()) {
          setSponsorData(sponsorDoc.data());
        } else {
          // Create new sponsor profile
          const newSponsorData = {
            businessId: user.uid,
            createdAt: new Date(),
            activeTier: null,
            assets: {
              logo: null,
              banner: null
            },
            analytics: {
              impressions: 0,
              clicks: 0,
              ctr: 0
            }
          };
          await setDoc(sponsorRef, newSponsorData);
          setSponsorData(newSponsorData);
        }
      } catch (err) {
        setError('Error fetching sponsor data');
        console.error(err);
      }
    };

    checkAuth();
  }, [navigate]);

  // Handle file upload
  const handleFileUpload = async (file, type) => {
    if (!file) return;

    const user = auth.currentUser;
    setUploading(true);
    setError('');

    try {
      const fileRef = ref(storage, `sponsor_assets/${user.uid}/${type}_${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Handle progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload progress: ', progress);
        },
        (error) => {
          setError('Upload failed: ' + error.message);
          setUploading(false);
        },
        async () => {
          // Upload complete
          const downloadURL = await getDownloadURL(fileRef);
          
          // Update sponsor document
          const sponsorRef = doc(db, 'sponsors', user.uid);
          await updateDoc(sponsorRef, {
            [`assets.${type}`]: downloadURL
          });

          setSponsorData(prev => ({
            ...prev,
            assets: {
              ...prev.assets,
              [type]: downloadURL
            }
          }));

          setMessage(`${type} uploaded successfully!`);
          setUploading(false);
        }
      );
    } catch (err) {
      setError('Upload failed: ' + err.message);
      setUploading(false);
    }
  };

  const handlePurchase = async (tier) => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      const sponsorRef = doc(db, 'sponsors', user.uid);
      await updateDoc(sponsorRef, {
        activeTier: tier.name,
        tierPurchaseDate: new Date(),
        tierExpiryDate: new Date(Date.now() + (tier.name === 'exclusive' ? 30 : tier.name === 'featured' ? 14 : 7) * 24 * 60 * 60 * 1000)
      });

      setMessage(`Successfully purchased ${tier.name} tier!`);
    } catch (err) {
      setError('Failed to process purchase: ' + err.message);
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-4 space-y-4">
      <header className="mb-8">
        <h1 className="text-3xl font-bold mb-2">Sponsorship Dashboard</h1>
        <p className="text-gray">Manage your Art Basel sponsorship presence</p>
      </header>

      <div className="space-y-4">
        {/* Tabs */}
        <div className="flex space-x-1 border-b bg-grayLight rounded">
          {['overview', 'content', 'analytics', 'settings'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 capitalize ${
                activeTab === tab
                  ? 'border-b-2 bg-white text-teal'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Overview Tab */}
        {activeTab === 'overview' && (
          <div className="grid md:grid-cols-3 gap-4">
            {Object.entries(SPONSORSHIP_TIERS).map(([key, tier]) => (
              <div key={key} className="border rounded-lg shadow-sm flex flex-col">
                <div className="p-6 border-b">
                  <h3 className="text-lg font-semibold">{tier.name}</h3>
                  <p className="text-gray-600">${tier.price} USD</p>
                </div>
                <div className="p-6 flex-grow">
                  <ul className="space-y-2">
                    {tier.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <span className="text-coral font-bold">✓</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="p-6 border-t">
                  <button
                    className="w-full flex items-center justify-center px-4 py-2 bg-mustard text-white rounded-md hover:bg-teal"
                    onClick={() => handlePurchase(tier)}
                  >
                    <CreditCard className="w-4 h-4 mr-2" />
                    Purchase
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Content Tab */}
        {activeTab === 'content' && (
          <div className="border rounded-lg shadow-sm">
            <div className="p-6 border-b">
              <h3 className="text-lg font-semibold">Content Manager</h3>
              <p className="text-gray-600">Upload and manage your sponsorship assets</p>
            </div>
            <div className="p-6">
              {error && <p className="text-red-500 mb-4">{error}</p>}
              {message && <p className="text-green-500 mb-4">{message}</p>}
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Logo Upload */}
                <div className="border-2 border-dashed rounded-lg p-8 text-center">
                  {sponsorData?.assets?.logo ? (
                    <div className="space-y-4">
                      <img src={sponsorData.assets.logo} alt="Logo" className="w-32 h-32 object-contain mx-auto" />
                      <button 
                        onClick={() => document.getElementById('logo-upload').click()}
                        className="px-4 py-2 border rounded-md hover:bg-gray-50"
                      >
                        Update Logo
                      </button>
                    </div>
                  ) : (
                    <>
                      <Upload className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                      <p className="text-gray-600">Upload your logo</p>
                      <input
                        id="logo-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e.target.files[0], 'logo')}
                      />
                      <button 
                        onClick={() => document.getElementById('logo-upload').click()}
                        className="mt-4 px-4 py-2 border rounded-md hover:bg-gray-50"
                        disabled={uploading}
                      >
                        {uploading ? 'Uploading...' : 'Select Logo'}
                      </button>
                    </>
                  )}
                </div>

                {/* Banner Upload */}
                <div className="border-2 border-dashed rounded-lg p-8 text-center">
                  {/* Similar structure as logo upload */}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Analytics Tab */}
        {activeTab === 'analytics' && (
          <div className="border rounded-lg shadow-sm">
            <div className="p-6 border-b">
              <h3 className="text-lg font-semibold">Performance Metrics</h3>
              <p className="text-gray-600">View your sponsorship impact</p>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-3 gap-4">
                {[
                  { value: '1,234', label: 'Impressions' },
                  { value: '56', label: 'Clicks' },
                  { value: '4.5%', label: 'CTR' },
                ].map((metric, index) => (
                  <div key={index} className="p-4 border rounded-lg text-center">
                    <p className="text-2xl font-bold">{metric.value}</p>
                    <p className="text-gray-600">{metric.label}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Settings Tab */}
        {activeTab === 'settings' && (
          <div className="border rounded-lg shadow-sm">
            <div className="p-6 border-b">
              <h3 className="text-lg font-semibold">Account Settings</h3>
              <p className="text-gray-600">Manage your sponsorship preferences</p>
            </div>
            <div className="p-6 space-y-4">
              {[
                { title: 'Billing Information', description: 'Manage payment methods' },
                { title: 'Notification Settings', description: 'Configure email preferences' },
              ].map((setting, index) => (
                <div key={index} className="flex items-center justify-between p-4 border rounded-lg">
                  <div>
                    <h3 className="font-medium">{setting.title}</h3>
                    <p className="text-gray-600">{setting.description}</p>
                  </div>
                  <button className="px-4 py-2 border rounded-md hover:bg-gray-50">
                    {setting.title === 'Billing Information' ? 'Update' : 'Configure'}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SponsorshipDashboard;