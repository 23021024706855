import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // Assuming you have Firebase auth initialized

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // New state to track the user object

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsAuthenticated(true);
        setUser(user); // Set the user object when authenticated
      } else {
        setIsAuthenticated(false);
        setUser(null); // Clear the user object when not authenticated
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user }}> {/* Provide user along with isAuthenticated */}
      {children}
    </AuthContext.Provider>
  );
};