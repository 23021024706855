import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';  // Import the CheckoutForm

// Initialize Stripe
const stripePromise = loadStripe('pk_live_51LrlqkLcU5LcUWNlSOtwn3rSRMvH87bGpzfo0bJbviZ2CfNyEoTQs8VEH5lOo7svlTAEjcuGQrYBiRu82qWc95Wa008aVte7VM');  // Replace with your Stripe publishable key

const SignupProCheckout = () => {
    return (
        <div className="max-w-md mx-auto mt-10 p-6 bg-tealLight shadow-md rounded-lg">
            <h1 className="text-2xl font-bold text-center mb-4">Upgrade to Pro Plan</h1>
            
            {/* Plan Description */}
            <p className="text-center text-gray-700 mb-4">
              Unlock unlimited listings, advanced analytics, and promotional tools to grow your business or events with the Pro Plan for just $9.99/month.
            </p>

            {/* Features Section */}
            <div className="plan-features mb-6">
                <h2 className="text-xl font-bold mb-3">What's Included</h2>
                <ul className="list-disc list-inside space-y-2">
                    <li>Unlimited event/business listings</li>
                    <li>Priority search placement</li>
                    <li>Offer more rewards/coins</li>
                    <li>Advanced analytics</li>
                    <li>Ad credits for promotions</li>
                </ul>
            </div>
            
            {/* Payment Amount Clarity */}
            <p className="text-center text-gray-700 mb-6">
              <strong>Total: $9.99 USD/month</strong>
            </p>

            {/* Checkout Form */}
            <Elements stripe={stripePromise}>
                <CheckoutForm plan="pro" amount={999} />  {/* Pass amount in cents (e.g., $10 => 1000 cents) */}
            </Elements>

            {/* Additional Info */}
            <p className="text-sm text-center mt-4 text-gray-500">
              Cancel anytime. Upgrade or downgrade as your needs change.
            </p>
        </div>
    );
};

export default SignupProCheckout;